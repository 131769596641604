






@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
@keyframes fadeInUp {
  0% {
    opacity: 0;
    display: block;
    transform: translate(0, 25%); }
  100% {
    opacity: 1;
    display: block;
    transform: translate(0, 0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    display: block;
    transform: translate(25%, 0); }
  100% {
    opacity: 1;
    display: block;
    transform: translate(0, 0); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    display: block;
    transform: translate(0, 0); }
  99% {
    opacity: 0;
    display: block;
    transform: translate(0, 25%); }
  100% {
    display: none; } }


body {
  font-size: 14px; }

.no-padding {
  padding: 0 !important; }

.flex-center {
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.no-border-radius {
  border-radius: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.a-center {
  text-align: center; }

.a-right {
  text-align: right; }


.sk-rotating-plane {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 40px auto;
  animation: sk-rotatePlane 1.2s infinite ease-in-out; }

@keyframes sk-rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }


.sk-double-bounce {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 40px auto; }
  .sk-double-bounce .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-doubleBounce 2.0s infinite ease-in-out; }
  .sk-double-bounce .sk-double-bounce2 {
    animation-delay: -1.0s; }

@keyframes sk-doubleBounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }


.sk-wave {
  margin: 40px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px; }
  .sk-wave .sk-rect {
    background-color: #333;
    height: 100%;
    width: 6px;
    display: inline-block;
    animation: sk-waveStretchDelay 1.2s infinite ease-in-out; }
  .sk-wave .sk-rect1 {
    animation-delay: -1.2s; }
  .sk-wave .sk-rect2 {
    animation-delay: -1.1s; }
  .sk-wave .sk-rect3 {
    animation-delay: -1s; }
  .sk-wave .sk-rect4 {
    animation-delay: -0.9s; }
  .sk-wave .sk-rect5 {
    animation-delay: -0.8s; }

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }


.sk-wandering-cubes {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative; }
  .sk-wandering-cubes .sk-cube {
    background-color: #333;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both; }
  .sk-wandering-cubes .sk-cube2 {
    animation-delay: -0.9s; }

@keyframes sk-wanderingCube {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: translateX(30px) rotate(-90deg) scale(0.5); }
  50% {
    
    transform: translateX(30px) translateY(30px) rotate(-179deg); }
  50.1% {
    transform: translateX(30px) translateY(30px) rotate(-180deg); }
  75% {
    transform: translateX(0) translateY(30px) rotate(-270deg) scale(0.5); }
  100% {
    transform: rotate(-360deg); } }


.sk-spinner-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto;
  background-color: #333;
  border-radius: 100%;
  animation: sk-pulseScaleOut 1.0s infinite ease-in-out; }

@keyframes sk-pulseScaleOut {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1);
    opacity: 0; } }


.sk-chasing-dots {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  animation: sk-chasingDotsRotate 2s infinite linear; }
  .sk-chasing-dots .sk-child {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #333;
    border-radius: 100%;
    animation: sk-chasingDotsBounce 2s infinite ease-in-out; }
  .sk-chasing-dots .sk-dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s; }

@keyframes sk-chasingDotsRotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chasingDotsBounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }


.sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center; }
  .sk-three-bounce .sk-child {
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; }
  .sk-three-bounce .sk-bounce1 {
    animation-delay: -0.32s; }
  .sk-three-bounce .sk-bounce2 {
    animation-delay: -0.16s; }

@keyframes sk-three-bounce {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }


.sk-circle {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative; }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }
  .sk-circle .sk-circle2 {
    transform: rotate(30deg); }
  .sk-circle .sk-circle3 {
    transform: rotate(60deg); }
  .sk-circle .sk-circle4 {
    transform: rotate(90deg); }
  .sk-circle .sk-circle5 {
    transform: rotate(120deg); }
  .sk-circle .sk-circle6 {
    transform: rotate(150deg); }
  .sk-circle .sk-circle7 {
    transform: rotate(180deg); }
  .sk-circle .sk-circle8 {
    transform: rotate(210deg); }
  .sk-circle .sk-circle9 {
    transform: rotate(240deg); }
  .sk-circle .sk-circle10 {
    transform: rotate(270deg); }
  .sk-circle .sk-circle11 {
    transform: rotate(300deg); }
  .sk-circle .sk-circle12 {
    transform: rotate(330deg); }
  .sk-circle .sk-circle2:before {
    animation-delay: -1.1s; }
  .sk-circle .sk-circle3:before {
    animation-delay: -1s; }
  .sk-circle .sk-circle4:before {
    animation-delay: -0.9s; }
  .sk-circle .sk-circle5:before {
    animation-delay: -0.8s; }
  .sk-circle .sk-circle6:before {
    animation-delay: -0.7s; }
  .sk-circle .sk-circle7:before {
    animation-delay: -0.6s; }
  .sk-circle .sk-circle8:before {
    animation-delay: -0.5s; }
  .sk-circle .sk-circle9:before {
    animation-delay: -0.4s; }
  .sk-circle .sk-circle10:before {
    animation-delay: -0.3s; }
  .sk-circle .sk-circle11:before {
    animation-delay: -0.2s; }
  .sk-circle .sk-circle12:before {
    animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }


.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 40px auto;
   }
  .sk-cube-grid .sk-cube {
    width: 33.33%;
    height: 33.33%;
    background-color: #333;
    float: left;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; }
  .sk-cube-grid .sk-cube1 {
    animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube2 {
    animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube3 {
    animation-delay: 0.4s; }
  .sk-cube-grid .sk-cube4 {
    animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube5 {
    animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube6 {
    animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube7 {
    animation-delay: 0.0s; }
  .sk-cube-grid .sk-cube8 {
    animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube9 {
    animation-delay: 0.2s; }

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1); }
  35% {
    transform: scale3D(0, 0, 1); } }


.sk-fading-circle {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative; }
  .sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both; }
  .sk-fading-circle .sk-circle2 {
    transform: rotate(30deg); }
  .sk-fading-circle .sk-circle3 {
    transform: rotate(60deg); }
  .sk-fading-circle .sk-circle4 {
    transform: rotate(90deg); }
  .sk-fading-circle .sk-circle5 {
    transform: rotate(120deg); }
  .sk-fading-circle .sk-circle6 {
    transform: rotate(150deg); }
  .sk-fading-circle .sk-circle7 {
    transform: rotate(180deg); }
  .sk-fading-circle .sk-circle8 {
    transform: rotate(210deg); }
  .sk-fading-circle .sk-circle9 {
    transform: rotate(240deg); }
  .sk-fading-circle .sk-circle10 {
    transform: rotate(270deg); }
  .sk-fading-circle .sk-circle11 {
    transform: rotate(300deg); }
  .sk-fading-circle .sk-circle12 {
    transform: rotate(330deg); }
  .sk-fading-circle .sk-circle2:before {
    animation-delay: -1.1s; }
  .sk-fading-circle .sk-circle3:before {
    animation-delay: -1s; }
  .sk-fading-circle .sk-circle4:before {
    animation-delay: -0.9s; }
  .sk-fading-circle .sk-circle5:before {
    animation-delay: -0.8s; }
  .sk-fading-circle .sk-circle6:before {
    animation-delay: -0.7s; }
  .sk-fading-circle .sk-circle7:before {
    animation-delay: -0.6s; }
  .sk-fading-circle .sk-circle8:before {
    animation-delay: -0.5s; }
  .sk-fading-circle .sk-circle9:before {
    animation-delay: -0.4s; }
  .sk-fading-circle .sk-circle10:before {
    animation-delay: -0.3s; }
  .sk-fading-circle .sk-circle11:before {
    animation-delay: -0.2s; }
  .sk-fading-circle .sk-circle12:before {
    animation-delay: -0.1s; }

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }


.sk-folding-cube {
  margin: 40px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg); }
  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1); }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    transform-origin: 100% 100%; }
  .sk-folding-cube .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg); }
  .sk-folding-cube .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg); }
  .sk-folding-cube .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg); }
  .sk-folding-cube .sk-cube2:before {
    animation-delay: 0.3s; }
  .sk-folding-cube .sk-cube3:before {
    animation-delay: 0.6s; }
  .sk-folding-cube .sk-cube4:before {
    animation-delay: 0.9s; }

@keyframes sk-foldCubeAngle {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(flags/4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(flags/1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(flags/4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(flags/1x1/ae.svg); }

.flag-icon-af {
  background-image: url(flags/4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(flags/1x1/af.svg); }

.flag-icon-ag {
  background-image: url(flags/4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(flags/1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(flags/4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(flags/1x1/ai.svg); }

.flag-icon-al {
  background-image: url(flags/4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(flags/1x1/al.svg); }

.flag-icon-am {
  background-image: url(flags/4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(flags/1x1/am.svg); }

.flag-icon-ao {
  background-image: url(flags/4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(flags/1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(flags/4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(flags/1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(flags/4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(flags/1x1/ar.svg); }

.flag-icon-as {
  background-image: url(flags/4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(flags/1x1/as.svg); }

.flag-icon-at {
  background-image: url(flags/4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(flags/1x1/at.svg); }

.flag-icon-au {
  background-image: url(flags/4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(flags/1x1/au.svg); }

.flag-icon-aw {
  background-image: url(flags/4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(flags/1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(flags/4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(flags/1x1/ax.svg); }

.flag-icon-az {
  background-image: url(flags/4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(flags/1x1/az.svg); }

.flag-icon-ba {
  background-image: url(flags/4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(flags/1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(flags/4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(flags/1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(flags/4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(flags/1x1/bd.svg); }

.flag-icon-be {
  background-image: url(flags/4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(flags/1x1/be.svg); }

.flag-icon-bf {
  background-image: url(flags/4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(flags/1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(flags/4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(flags/1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(flags/4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(flags/1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(flags/4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(flags/1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(flags/4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(flags/1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(flags/4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(flags/1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(flags/4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(flags/1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(flags/4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(flags/1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(flags/4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(flags/1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(flags/4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(flags/1x1/bq.svg); }

.flag-icon-br {
  background-image: url(flags/4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(flags/1x1/br.svg); }

.flag-icon-bs {
  background-image: url(flags/4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(flags/1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(flags/4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(flags/1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(flags/4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(flags/1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(flags/4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(flags/1x1/bw.svg); }

.flag-icon-by {
  background-image: url(flags/4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(flags/1x1/by.svg); }

.flag-icon-bz {
  background-image: url(flags/4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(flags/1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(flags/4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(flags/1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(flags/4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(flags/1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(flags/4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(flags/1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(flags/4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(flags/1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(flags/4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(flags/1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(flags/4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(flags/1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(flags/4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(flags/1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(flags/4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(flags/1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(flags/4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(flags/1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(flags/4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(flags/1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(flags/4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(flags/1x1/cn.svg); }

.flag-icon-co {
  background-image: url(flags/4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(flags/1x1/co.svg); }

.flag-icon-cr {
  background-image: url(flags/4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(flags/1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(flags/4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(flags/1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(flags/4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(flags/1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(flags/4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(flags/1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(flags/4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(flags/1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(flags/4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(flags/1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(flags/4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(flags/1x1/cz.svg); }

.flag-icon-de {
  background-image: url(flags/4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(flags/1x1/de.svg); }

.flag-icon-dj {
  background-image: url(flags/4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(flags/1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(flags/4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(flags/1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(flags/4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(flags/1x1/dm.svg); }

.flag-icon-do {
  background-image: url(flags/4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(flags/1x1/do.svg); }

.flag-icon-dz {
  background-image: url(flags/4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(flags/1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(flags/4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(flags/1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(flags/4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(flags/1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(flags/4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(flags/1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(flags/4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(flags/1x1/eh.svg); }

.flag-icon-er {
  background-image: url(flags/4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(flags/1x1/er.svg); }

.flag-icon-es {
  background-image: url(flags/4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(flags/1x1/es.svg); }

.flag-icon-et {
  background-image: url(flags/4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(flags/1x1/et.svg); }

.flag-icon-fi {
  background-image: url(flags/4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(flags/1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(flags/4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(flags/1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(flags/4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(flags/1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(flags/4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(flags/1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(flags/4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(flags/1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(flags/4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(flags/1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(flags/4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(flags/1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(flags/4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(flags/1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(flags/4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(flags/1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(flags/4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(flags/1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(flags/4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(flags/1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(flags/4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(flags/1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(flags/4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(flags/1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(flags/4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(flags/1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(flags/4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(flags/1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(flags/4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(flags/1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(flags/4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(flags/1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(flags/4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(flags/1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(flags/4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(flags/1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(flags/4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(flags/1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(flags/4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(flags/1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(flags/4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(flags/1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(flags/4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(flags/1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(flags/4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(flags/1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(flags/4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(flags/1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(flags/4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(flags/1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(flags/4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(flags/1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(flags/4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(flags/1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(flags/4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(flags/1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(flags/4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(flags/1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(flags/4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(flags/1x1/hu.svg); }

.flag-icon-id {
  background-image: url(flags/4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(flags/1x1/id.svg); }

.flag-icon-ie {
  background-image: url(flags/4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(flags/1x1/ie.svg); }

.flag-icon-il {
  background-image: url(flags/4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(flags/1x1/il.svg); }

.flag-icon-im {
  background-image: url(flags/4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(flags/1x1/im.svg); }

.flag-icon-in {
  background-image: url(flags/4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(flags/1x1/in.svg); }

.flag-icon-io {
  background-image: url(flags/4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(flags/1x1/io.svg); }

.flag-icon-iq {
  background-image: url(flags/4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(flags/1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(flags/4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(flags/1x1/ir.svg); }

.flag-icon-is {
  background-image: url(flags/4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(flags/1x1/is.svg); }

.flag-icon-it {
  background-image: url(flags/4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(flags/1x1/it.svg); }

.flag-icon-je {
  background-image: url(flags/4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(flags/1x1/je.svg); }

.flag-icon-jm {
  background-image: url(flags/4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(flags/1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(flags/4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(flags/1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(flags/4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(flags/1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(flags/4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(flags/1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(flags/4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(flags/1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(flags/4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(flags/1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(flags/4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(flags/1x1/ki.svg); }

.flag-icon-km {
  background-image: url(flags/4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(flags/1x1/km.svg); }

.flag-icon-kn {
  background-image: url(flags/4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(flags/1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(flags/4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(flags/1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(flags/4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(flags/1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(flags/4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(flags/1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(flags/4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(flags/1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(flags/4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(flags/1x1/kz.svg); }

.flag-icon-la {
  background-image: url(flags/4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(flags/1x1/la.svg); }

.flag-icon-lb {
  background-image: url(flags/4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(flags/1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(flags/4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(flags/1x1/lc.svg); }

.flag-icon-li {
  background-image: url(flags/4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(flags/1x1/li.svg); }

.flag-icon-lk {
  background-image: url(flags/4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(flags/1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(flags/4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(flags/1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(flags/4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(flags/1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(flags/4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(flags/1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(flags/4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(flags/1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(flags/4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(flags/1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(flags/4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(flags/1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(flags/4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(flags/1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(flags/4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(flags/1x1/mc.svg); }

.flag-icon-md {
  background-image: url(flags/4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(flags/1x1/md.svg); }

.flag-icon-me {
  background-image: url(flags/4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(flags/1x1/me.svg); }

.flag-icon-mf {
  background-image: url(flags/4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(flags/1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(flags/4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(flags/1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(flags/4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(flags/1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(flags/4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(flags/1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(flags/4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(flags/1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(flags/4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(flags/1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(flags/4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(flags/1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(flags/4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(flags/1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(flags/4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(flags/1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(flags/4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(flags/1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(flags/4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(flags/1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(flags/4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(flags/1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(flags/4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(flags/1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(flags/4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(flags/1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(flags/4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(flags/1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(flags/4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(flags/1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(flags/4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(flags/1x1/mx.svg); }

.flag-icon-my {
  background-image: url(flags/4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(flags/1x1/my.svg); }

.flag-icon-mz {
  background-image: url(flags/4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(flags/1x1/mz.svg); }

.flag-icon-na {
  background-image: url(flags/4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(flags/1x1/na.svg); }

.flag-icon-nc {
  background-image: url(flags/4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(flags/1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(flags/4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(flags/1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(flags/4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(flags/1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(flags/4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(flags/1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(flags/4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(flags/1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(flags/4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(flags/1x1/nl.svg); }

.flag-icon-no {
  background-image: url(flags/4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(flags/1x1/no.svg); }

.flag-icon-np {
  background-image: url(flags/4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(flags/1x1/np.svg); }

.flag-icon-nr {
  background-image: url(flags/4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(flags/1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(flags/4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(flags/1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(flags/4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(flags/1x1/nz.svg); }

.flag-icon-om {
  background-image: url(flags/4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(flags/1x1/om.svg); }

.flag-icon-pa {
  background-image: url(flags/4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(flags/1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(flags/4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(flags/1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(flags/4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(flags/1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(flags/4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(flags/1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(flags/4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(flags/1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(flags/4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(flags/1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(flags/4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(flags/1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(flags/4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(flags/1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(flags/4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(flags/1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(flags/4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(flags/1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(flags/4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(flags/1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(flags/4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(flags/1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(flags/4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(flags/1x1/pw.svg); }

.flag-icon-py {
  background-image: url(flags/4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(flags/1x1/py.svg); }

.flag-icon-qa {
  background-image: url(flags/4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(flags/1x1/qa.svg); }

.flag-icon-re {
  background-image: url(flags/4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(flags/1x1/re.svg); }

.flag-icon-ro {
  background-image: url(flags/4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(flags/1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(flags/4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(flags/1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(flags/4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(flags/1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(flags/4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(flags/1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(flags/4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(flags/1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(flags/4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(flags/1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(flags/4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(flags/1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(flags/4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(flags/1x1/sd.svg); }

.flag-icon-se {
  background-image: url(flags/4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(flags/1x1/se.svg); }

.flag-icon-sg {
  background-image: url(flags/4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(flags/1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(flags/4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(flags/1x1/sh.svg); }

.flag-icon-si {
  background-image: url(flags/4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(flags/1x1/si.svg); }

.flag-icon-sj {
  background-image: url(flags/4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(flags/1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(flags/4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(flags/1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(flags/4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(flags/1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(flags/4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(flags/1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(flags/4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(flags/1x1/sn.svg); }

.flag-icon-so {
  background-image: url(flags/4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(flags/1x1/so.svg); }

.flag-icon-sr {
  background-image: url(flags/4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(flags/1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(flags/4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(flags/1x1/ss.svg); }

.flag-icon-st {
  background-image: url(flags/4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(flags/1x1/st.svg); }

.flag-icon-sv {
  background-image: url(flags/4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(flags/1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(flags/4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(flags/1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(flags/4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(flags/1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(flags/4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(flags/1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(flags/4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(flags/1x1/tc.svg); }

.flag-icon-td {
  background-image: url(flags/4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(flags/1x1/td.svg); }

.flag-icon-tf {
  background-image: url(flags/4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(flags/1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(flags/4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(flags/1x1/tg.svg); }

.flag-icon-th {
  background-image: url(flags/4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(flags/1x1/th.svg); }

.flag-icon-tj {
  background-image: url(flags/4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(flags/1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(flags/4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(flags/1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(flags/4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(flags/1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(flags/4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(flags/1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(flags/4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(flags/1x1/tn.svg); }

.flag-icon-to {
  background-image: url(flags/4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(flags/1x1/to.svg); }

.flag-icon-tr {
  background-image: url(flags/4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(flags/1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(flags/4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(flags/1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(flags/4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(flags/1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(flags/4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(flags/1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(flags/4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(flags/1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(flags/4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(flags/1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(flags/4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(flags/1x1/ug.svg); }

.flag-icon-um {
  background-image: url(flags/4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(flags/1x1/um.svg); }

.flag-icon-us {
  background-image: url(flags/4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(flags/1x1/us.svg); }

.flag-icon-uy {
  background-image: url(flags/4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(flags/1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(flags/4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(flags/1x1/uz.svg); }

.flag-icon-va {
  background-image: url(flags/4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(flags/1x1/va.svg); }

.flag-icon-vc {
  background-image: url(flags/4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(flags/1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(flags/4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(flags/1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(flags/4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(flags/1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(flags/4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(flags/1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(flags/4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(flags/1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(flags/4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(flags/1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(flags/4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(flags/1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(flags/4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(flags/1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(flags/4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(flags/1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(flags/4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(flags/1x1/yt.svg); }

.flag-icon-za {
  background-image: url(flags/4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(flags/1x1/za.svg); }

.flag-icon-zm {
  background-image: url(flags/4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(flags/1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(flags/4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(flags/1x1/zw.svg); }

.flag-icon-eu {
  background-image: url(flags/4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(flags/1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(flags/4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(flags/1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(flags/4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(flags/1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(flags/4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(flags/1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(flags/4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(flags/1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(flags/4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(flags/1x1/un.svg); }

html, body {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #444;
  position: relative; }

body {
  min-height: 100vh;
  background-color: #e7edee;
  overflow-x: hidden; }
  body .app {
    min-height: 100vh; }
  body .app-container {
    padding: 0px;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
    padding-left: 180px; }
    body .app-container .content-heading {
      margin-bottom: 30px; }
      body .app-container .content-heading .title {
        font-size: 1.75em;
        font-weight: 200; }
    body .app-container > div:first-child, body .app-container > ui-view > div:first-child, body .app-container > ui-view > ui-view > div:first-child, body .app-container > ui-view > ui-view > ui-view > div:first-child {
      margin-top: 30px; }
    body .app-container > div.row, body .app-container > ui-view > div.row, body .app-container > ui-view > ui-view > div.row, body .app-container > ui-view > ui-view > ui-view > div.row {
      padding-left: 30px;
      padding-right: 30px; }
      body .app-container > div.row > [class*="col-"], body .app-container > ui-view > div.row > [class*="col-"], body .app-container > ui-view > ui-view > div.row > [class*="col-"], body .app-container > ui-view > ui-view > ui-view > div.row > [class*="col-"] {
        margin-bottom: 30px; }
    body .app-container .app-heading {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start;
      padding-left: 30px;
      padding-right: 30px; }
      body .app-container .app-heading .profile-img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 30px; }
      body .app-container .app-heading .app-title {
        -ms-flex: 1;
            flex: 1; }
        body .app-container .app-heading .app-title .title {
          font-size: 2em;
          font-weight: 200; }
          body .app-container .app-heading .app-title .title .highlight {
            color: #29c75f; }
        body .app-container .app-heading .app-title .description {
          margin-top: 5px;
          color: #8d9293; }
    body .app-container.app-login {
      min-height: 100vh !important; }
      body .app-container.app-login > div:first-child, body .app-container.app-login > ui-view > div:first-child, body .app-container.app-login > ui-view > ui-view > div:first-child, body .app-container.app-login > ui-view > ui-view > ui-view > div:first-child {
        margin-top: 0;
        min-height: 100vh !important; }
    body .app-container.app-full {
      height: 100vh !important;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: start;
          align-items: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
      body .app-container.app-full > ui-view {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: start;
            align-items: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
        padding: 0 30px;
        margin-bottom: 30px;
        -ms-flex: 1;
            flex: 1; }
      body .app-container.app-full > div:first-child, body .app-container.app-full > ui-view > div:first-child, body .app-container.app-full > ui-view > ui-view > div:first-child, body .app-container.app-full > ui-view > ui-view > ui-view > div:first-child {
        margin-top: 0; }
      body .app-container.app-full > * {
        width: 100%; }

* {
  box-sizing: border-box; }

*:focus {
  outline: 0; }

a:hover {
  text-decoration: none; }

.full-height {
  height: 100%; }

.row {
  margin-bottom: 0; }
  .row > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }
  .row.full-height {
    height: 100%; }
    .row.full-height > [class*="col-"] {
      height: 100%; }
  .row.no-gap {
    margin-left: 0;
    margin-right: 0; }
    .row.no-gap > [class*="col-"] {
      padding-left: 0;
      padding-right: 0; }
  .row.full-width {
    padding-left: 0px !important;
    padding-right: 0px !important; }

@media (max-width: 767px) {
  .row {
    margin-left: -10.75px;
    margin-right: -10.75px; }
    .row > [class*="col-"] {
      padding-left: 10.75px;
      padding-right: 10.75px; }
  body .app-container {
    padding-left: 0;
    padding-top: 90px; }
    body .app-container .app-heading {
      padding-left: 15px;
      padding-right: 15px; }
    body .app-container.app-full {
      padding-top: 70px; }
    body .app-container > div:first-child, body .app-container > ui-view > div:first-child, body .app-container > ui-view > ui-view > div:first-child, body .app-container > ui-view > ui-view > ui-view > div:first-child {
      margin-top: 0px; }
    body .app-container > div.app-heading-container, body .app-container > ui-view > div.app-heading-container, body .app-container > ui-view > ui-view > div.app-heading-container, body .app-container > ui-view > ui-view > ui-view > div.app-heading-container {
      margin-top: 0; }
      body .app-container > div.app-heading-container .app-heading, body .app-container > ui-view > div.app-heading-container .app-heading, body .app-container > ui-view > ui-view > div.app-heading-container .app-heading, body .app-container > ui-view > ui-view > ui-view > div.app-heading-container .app-heading {
        padding: 30px; }
      body .app-container > div.app-heading-container ul.app-tab, body .app-container > ui-view > div.app-heading-container ul.app-tab, body .app-container > ui-view > ui-view > div.app-heading-container ul.app-tab, body .app-container > ui-view > ui-view > ui-view > div.app-heading-container ul.app-tab {
        margin-bottom: 15px;
        padding: 0px 15px; }
    body .app-container > div.row, body .app-container > ui-view > div.row, body .app-container > ui-view > ui-view > div.row, body .app-container > ui-view > ui-view > ui-view > div.row {
      padding-left: 15px;
      padding-right: 15px; }
      body .app-container > div.row > [class*="col-"], body .app-container > ui-view > div.row > [class*="col-"], body .app-container > ui-view > ui-view > div.row > [class*="col-"], body .app-container > ui-view > ui-view > ui-view > div.row > [class*="col-"] {
        margin-bottom: 15px;
        padding-left: 7.5px;
        padding-right: 7.5px; } }

.colorbox {
  width: 100%;
  overflow: hidden;
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px; }
  .colorbox .color1, .colorbox .color2 {
    width: 50%;
    float: left; }
    .colorbox .color1 .color, .colorbox .color2 .color {
      width: 100%;
      min-height: 60px; }
  .colorbox .heading {
    text-align: center;
    padding: 5px 10px; }
    .colorbox .heading .title {
      font-size: 0.9em; }
    .colorbox .heading .subtitle {
      font-size: 0.7em; }

@media (max-width: 767px) {
  .colorbox {
    margin-bottom: 10px; } }

.navbar {
  height: 90px;
  z-index: 100008;
  background-color: transparent;
  border-bottom: 0 solid transparent;
  border-top: 0;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 15px;
  margin-bottom: 30px; }
  .navbar .navbar-header {
    height: 90px;
    padding: 0px;
    width: 180px;
    margin-left: -15px;
    position: relative;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar .navbar-header .navbar-toggle {
      position: relative;
      display: none;
      height: 100%;
      border: 0;
      border-right: 1px solid #dfe6e8;
      padding: 2rem;
      margin: 0;
      border-radius: 0;
      z-index: 1; }
      .navbar .navbar-header .navbar-toggle:hover, .navbar .navbar-header .navbar-toggle:focus {
        cursor: pointer;
        background-color: transparent; }
    .navbar .navbar-header .navbar-brand {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      height: 90px;
      font-size: 1.2em;
      font-weight: 300;
      position: relative;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      color: #444; }
      .navbar .navbar-header .navbar-brand .highlight {
        padding: 2px 5px;
        font-weight: 200;
        color: #FFF;
        border-radius: 2px;
        margin-right: 5px; }
  .navbar .navbar-collapse {
    background-color: #FFF;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 1px 2px #c8d1d3; }
    .navbar .navbar-collapse .navbar-nav {
      height: 89px;
      border-bottom: 1px solid transparent;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start;
      margin-top: 0;
      margin-bottom: 0; }
      .navbar .navbar-collapse .navbar-nav > li {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: start;
            justify-content: flex-start;
        height: 100%; }
        .navbar .navbar-collapse .navbar-nav > li a, .navbar .navbar-collapse .navbar-nav > li a:hover, .navbar .navbar-collapse .navbar-nav > li a:focus {
          background-color: transparent;
          font-size: 1.1em;
          font-family: 'Open Sans', sans-serif; }
        .navbar .navbar-collapse .navbar-nav > li.navbar-title {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: start;
              justify-content: flex-start;
          font-weight: 300;
          font-size: 1.3em;
          height: 50%;
          padding: 0 6rem;
          padding-left: 45px; }
          .navbar .navbar-collapse .navbar-nav > li.navbar-title span {
            padding: 0.5rem 0; }
          .navbar .navbar-collapse .navbar-nav > li.navbar-title .highlight {
            font-weight: 400; }
        .navbar .navbar-collapse .navbar-nav > li.navbar-search input {
          border: none;
          height: 40px;
          padding: 0 20px;
          font-weight: 200;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border: 1px solid #dfe6e8;
          border-right: none;
          background-color: transparent; }
        .navbar .navbar-collapse .navbar-nav > li.navbar-search .btn-search {
          width: 40px;
          height: 40px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          border: 1px solid #dfe6e8;
          border-left: none;
          background-color: transparent;
          color: #dfe6e8;
          font-size: 1.4em; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown:after {
          position: absolute;
          top: auto;
          left: 50%;
          bottom: 0;
          right: auto;
          width: 100%;
          height: 100%;
          content: '';
          display: block;
          width: 0;
          height: 0;
          opacity: 0;
          visibility: hidden;
          border-left: 16px solid transparent;
          border-right: 16px solid transparent;
          border-bottom: 16px solid #dfe6e8;
          transform: translate(-50%, 0);
          transition: all 0.3s ease; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown:hover .dropdown-menu {
          display: block;
          visibility: visible;
          opacity: 1;
          transform: translate(0, -24px); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown:hover:after {
          display: block;
          visibility: visible;
          opacity: 1;
          transform: translate(-50%, -22px); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
          display: block;
          visibility: hidden;
          position: absolute;
          right: 0;
          top: 100%;
          opacity: 0;
          min-width: 300px;
          transition: all 0.3s ease;
          transform: translate(0, 0);
          padding-top: 0;
          padding-bottom: 0;
          border: 1px solid #dfe6e8; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu .profile-info .username {
            margin-top: 0;
            margin-bottom: 0;
            background-color: rgba(141, 146, 147, 0.1);
            color: #444;
            border-radius: 3px;
            padding: 1.2rem;
            font-size: 1.6em;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 auto;
            font-weight: 200; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul {
            list-style: none;
            padding: 0;
            margin: 0; }
            .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li {
              border: 0;
              border-radius: 0;
              padding: 0;
              border-bottom: 1px solid rgba(141, 146, 147, 0.1);
              margin-bottom: 0;
              font-weight: 300;
              font-size: 0.9em; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li a {
                display: block;
                text-decoration: none;
                color: #444;
                padding: 1.2rem;
                font-weight: 400; }
                .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li a .message {
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-direction: row;
                      flex-direction: row;
                  -ms-flex-wrap: nowrap;
                      flex-wrap: nowrap;
                  -ms-flex-align: center;
                      align-items: center;
                  -ms-flex-pack: start;
                      justify-content: flex-start;
                  padding-right: 40px; }
                  .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li a .message .content {
                    -ms-flex: 1;
                        flex: 1; }
                    .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li a .message .content .title {
                      font-weight: 400;
                      font-size: 1em; }
                    .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li a .message .content .description {
                      font-weight: 400;
                      font-size: 0.85em;
                      opacity: 0.4; }
                  .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li a .message .profile {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 8px; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li span.badge {
                border-radius: 3px;
                font-weight: 400;
                font-weight: 1.4em; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li:hover a {
                background-color: rgba(141, 146, 147, 0.1);
                color: #222;
                cursor: pointer; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li.empty {
                color: #8d9293;
                font-weight: 200; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li.dropdown-header {
                background: transparent;
                padding: 1.2rem;
                color: #222;
                font-weight: 400;
                background-color: rgba(141, 146, 147, 0.1);
                border-bottom: none; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li.dropdown-footer {
                background-color: transparent;
                border-top: 0;
                font-weight: 300;
                font-size: 0.9em;
                text-align: right; }
                .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li.dropdown-footer a {
                  color: #8d9293;
                  padding: 1.2rem; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li:last-child {
                border-bottom: 0; }
              .navbar .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu > ul > li.dropdown-empty {
                padding: 2rem;
                text-align: center; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle {
          text-align: center;
          display: block;
          padding: 0 8px; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle .icon {
            font-size: 1.6em;
            width: 45px;
            height: 45px;
            background-color: rgba(141, 146, 147, 0.15);
            color: rgba(141, 146, 147, 0.3);
            transition: all 0.2s ease;
            border-radius: 50%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle .count {
            position: absolute;
            top: 0;
            left: auto;
            bottom: auto;
            right: 0;
            width: 100%;
            height: 100%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
            font-size: 0.70em;
            font-weight: 400;
            color: #FFF;
            background-color: rgba(141, 146, 147, 0.6);
            box-shadow: 0 1px 2px rgba(141, 146, 147, 0.1);
            padding: 3px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 1em; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle .title {
            display: none; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle:hover .icon {
            transform: scale(1.1) rotate(7deg); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.notification.danger a.dropdown-toggle .icon {
          background-color: rgba(231, 76, 60, 0.15);
          color: rgba(231, 76, 60, 0.3); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.notification.danger a.dropdown-toggle .count {
          background-color: rgba(231, 76, 60, 0.7);
          box-shadow: 0 1px 2px rgba(231, 76, 60, 0.1); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.notification.warning a.dropdown-toggle .icon {
          background-color: rgba(252, 130, 41, 0.15);
          color: rgba(252, 130, 41, 0.3); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.notification.warning a.dropdown-toggle .count {
          background-color: rgba(252, 130, 41, 0.7);
          box-shadow: 0 1px 2px rgba(252, 130, 41, 0.1); }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.profile > a {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: start;
              justify-content: flex-start; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.profile > a .profile-img {
            width: 60px;
            height: 60px;
            border-radius: 50%; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.profile > a .title {
            display: none; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.open > a, .navbar .navbar-collapse .navbar-nav > li.dropdown.open > a:hover, .navbar .navbar-collapse .navbar-nav > li.dropdown.open > a a:focus {
          background-color: transparent; }
      .navbar .navbar-collapse .navbar-nav.navbar-mobile {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: start;
            align-items: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
        display: none;
        position: relative; }
        .navbar .navbar-collapse .navbar-nav.navbar-mobile > li {
          height: 100%; }
          .navbar .navbar-collapse .navbar-nav.navbar-mobile > li .navbar-toggle, .navbar .navbar-collapse .navbar-nav.navbar-mobile > li .sidebar-toggle {
            height: 100%;
            border: 0;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 0;
            border-radius: 0;
            padding: 0 15px;
            display: block;
            background-color: transparent;
            font-size: 1.4em;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center; }
            .navbar .navbar-collapse .navbar-nav.navbar-mobile > li .navbar-toggle .profile-img, .navbar .navbar-collapse .navbar-nav.navbar-mobile > li .sidebar-toggle .profile-img {
              width: 40px;
              height: 40px;
              border-radius: 50%; }
          .navbar .navbar-collapse .navbar-nav.navbar-mobile > li .sidebar-toggle {
            position: absolute;
            top: 0;
            left: 0;
            bottom: auto;
            right: auto;
            width: auto;
            height: 100%;
            z-index: 100010; }
          .navbar .navbar-collapse .navbar-nav.navbar-mobile > li .navbar-toggle {
            position: absolute;
            top: 0;
            left: auto;
            bottom: auto;
            right: 0;
            width: auto;
            height: 100%;
            z-index: 100010; }
          .navbar .navbar-collapse .navbar-nav.navbar-mobile > li.logo {
            -ms-flex: 1;
                flex: 1; }
        .navbar .navbar-collapse .navbar-nav.navbar-mobile .navbar-brand {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: center;
              justify-content: center;
          height: 90px;
          font-size: 1.2em;
          font-weight: 300;
          position: relative;
          margin: 0 auto;
          width: 100%;
          text-align: center;
          color: #444; }
          .navbar .navbar-collapse .navbar-nav.navbar-mobile .navbar-brand .highlight {
            padding: 2px 5px;
            font-weight: 200;
            color: #FFF;
            border-radius: 2px;
            margin-right: 5px;
            background-color: #29c75f; }

.app-landing .navbar .navbar-collapse {
  background-color: transparent;
  box-shadow: none; }

@media (max-width: 767px) {
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    height: 70px;
    padding: 0px; }
    .navbar .navbar-header {
      height: 70px;
      width: auto; }
      .navbar .navbar-header .navbar-brand {
        height: 70px; }
      .navbar .navbar-header .navbar-toggle {
        display: block; }
    .navbar .navbar-collapse {
      border-radius: 0; }
      .navbar .navbar-collapse .navbar-nav {
        height: 70px;
        display: none; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown:after, .navbar .navbar-collapse .navbar-nav > li.dropdown > .dropdown-menu {
          display: none !important; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle {
          padding: 10px;
          width: 100%;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: start;
              justify-content: flex-start; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle .icon {
            margin-right: 10px;
            background-color: transparent !important; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle .count {
            position: relative;
            font-size: 0.9em;
            width: 30px;
            height: 30px; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.notification a.dropdown-toggle .title {
            -ms-flex: 1;
                flex: 1;
            text-align: left;
            display: block; }
        .navbar .navbar-collapse .navbar-nav > li.dropdown.profile {
          border-bottom: none !important; }
          .navbar .navbar-collapse .navbar-nav > li.dropdown.profile > a {
            width: 100%; }
            .navbar .navbar-collapse .navbar-nav > li.dropdown.profile > a .profile-img {
              width: 40px;
              height: 40px;
              margin-right: 10px; }
            .navbar .navbar-collapse .navbar-nav > li.dropdown.profile > a .title {
              display: block; }
        .navbar .navbar-collapse .navbar-nav.navbar-mobile {
          display: -ms-flexbox;
          display: flex; }
  .app-container.__navbar .navbar .navbar-collapse .navbar-nav {
    height: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
    .app-container.__navbar .navbar .navbar-collapse .navbar-nav > li {
      width: 100%;
      border-bottom: 1px solid #f0f4f5; }
      .app-container.__navbar .navbar .navbar-collapse .navbar-nav > li.navbar-title {
        display: none; }
      .app-container.__navbar .navbar .navbar-collapse .navbar-nav > li.navbar-search {
        padding: 4px;
        background-color: #dfe6e8; }
        .app-container.__navbar .navbar .navbar-collapse .navbar-nav > li.navbar-search input {
          padding: 15px;
          width: 100%;
          background-color: #FFF; }
        .app-container.__navbar .navbar .navbar-collapse .navbar-nav > li.navbar-search .btn-search {
          background-color: #FFF; }
  .app-container.__navbar .navbar .navbar-collapse .navbar-mobile {
    height: 70px;
    -ms-flex-direction: row;
        flex-direction: row;
    border-bottom: none; }
    .app-container.__navbar .navbar .navbar-collapse .navbar-mobile > li {
      width: auto; } }

@media (max-width: 480px) {
  .navbar .navbar-header .navbar-toggle {
    position: absolute; } }

.navbar .navbar-header .navbar-brand .highlight, .navbar .navbar-mobile .navbar-brand .highlight {
  background-color: #29c75f; }

.navbar.__inverse .navbar-header .navbar-brand, .navbar.__inverse .navbar-mobile .navbar-brand {
  color: #FFF; }
  .navbar.__inverse .navbar-header .navbar-brand .highlight, .navbar.__inverse .navbar-mobile .navbar-brand .highlight {
    background-color: #29c75f; }

aside.app-sidebar {
  position: absolute;
  top: auto;
  left: 0;
  bottom: auto;
  right: auto;
  width: auto;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  z-index: 100108;
  min-height: 100vh;
  background-color: #FFF;
  box-shadow: 0 2px 2px rgba(68, 68, 68, 0.2);
  transition: all 0.3s ease; }
  aside.app-sidebar .sidebar-header {
    height: 90px;
    width: 180px;
    padding: 0 10px;
    position: relative; }
    aside.app-sidebar .sidebar-header .sidebar-brand {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      height: 90px;
      font-size: 1.2em;
      font-weight: 300;
      color: #444; }
      aside.app-sidebar .sidebar-header .sidebar-brand .highlight {
        padding: 2px 5px;
        font-weight: 200;
        color: #FFF;
        border-radius: 2px;
        margin-right: 5px; }
    aside.app-sidebar .sidebar-header .sidebar-toggle {
      display: none;
      position: absolute;
      top: 0;
      left: auto;
      bottom: auto;
      right: 0;
      width: auto;
      height: 100%;
      height: 100%;
      border: 0;
      border-radius: 0;
      padding: 0 15px;
      background-color: transparent;
      font-size: 1.4em; }
  aside.app-sidebar .sidebar-menu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: start;
        justify-content: flex-start;
    width: 180px;
    -ms-flex: 1;
        flex: 1; }
    aside.app-sidebar .sidebar-menu ul.sidebar-nav {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start;
      height: 100%;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 4rem 0; }
      aside.app-sidebar .sidebar-menu ul.sidebar-nav > li {
        width: 100%; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a {
          padding: 1rem 0;
          display: block;
          text-align: center;
          color: #444;
          text-decoration: none; }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .icon {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
            display: block;
            margin: 0 auto;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            color: #FFF;
            margin-bottom: 0.6em; }
            aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .icon i.fa, aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .icon i.ion {
              width: 100%;
              height: 100%;
              font-size: 2em;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: row;
                  flex-direction: row;
              -ms-flex-wrap: nowrap;
                  flex-wrap: nowrap;
              -ms-flex-align: center;
                  align-items: center;
              -ms-flex-pack: center;
                  justify-content: center;
              transition: transform 0.2s ease; }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .title {
            font-weight: 400;
            font-size: 0.95em;
            color: #8d9293;
            text-transform: uppercase; }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a:hover .icon i.fa, aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a:hover .icon i.ion {
            transform: scale(1.25); }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-toggle {
          position: relative; }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-toggle:before {
            position: absolute;
            top: 0;
            left: auto;
            bottom: auto;
            right: 30px;
            width: auto;
            height: 100%;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: center;
                justify-content: center;
            font-family: FontAwesome;
            content: "\f107";
            color: #dfe6e8;
            font-size: 1.4em;
            transition: all 0.3s ease;
            display: none; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu {
          display: block;
          visibility: hidden;
          position: absolute;
          opacity: 0;
          left: 100%;
          top: 50%;
          min-width: 300px;
          transition: all 0.3s ease;
          transform: translate(0px, -50%);
          border: 1px solid #dfe6e8; }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul {
            list-style: none;
            padding: 1rem 2rem;
            margin: 0; }
            aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul:after {
              position: absolute;
              top: 50%;
              left: 0;
              bottom: auto;
              right: auto;
              width: 100%;
              height: 100%;
              content: '';
              display: block;
              width: 0;
              height: 0;
              transform: translate(-100%, -100%);
              border-top: 15px solid transparent;
              border-bottom: 15px solid transparent;
              border-right: 15px solid #dfe6e8; }
            aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li {
              border: 0;
              border-radius: 0;
              padding: 0;
              margin-bottom: 0;
              font-weight: 400;
              font-size: 1em; }
              aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li.section {
                padding: 1rem;
                padding-left: 3rem;
                font-weight: 300;
                font-size: 1.1em;
                position: relative; }
                aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li.section i {
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: auto;
                  right: auto;
                  width: auto;
                  height: 100%;
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-direction: row;
                      flex-direction: row;
                  -ms-flex-wrap: nowrap;
                      flex-wrap: nowrap;
                  -ms-flex-align: center;
                      align-items: center;
                  -ms-flex-pack: center;
                      justify-content: center;
                  font-size: 1.2em; }
              aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li.line {
                height: 1px;
                background-color: rgba(223, 230, 232, 0.5);
                position: relative;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem; }
              aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li a {
                display: block;
                text-decoration: none;
                color: #666;
                padding: 1rem 1rem;
                padding-left: 3rem;
                position: relative; }
                aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li a:before {
                  position: absolute;
                  top: 0;
                  left: auto;
                  bottom: auto;
                  right: 15px;
                  width: auto;
                  height: 100%;
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-direction: row;
                      flex-direction: row;
                  -ms-flex-wrap: nowrap;
                      flex-wrap: nowrap;
                  -ms-flex-align: center;
                      align-items: center;
                  -ms-flex-pack: center;
                      justify-content: center;
                  font-family: FontAwesome;
                  content: "\f105";
                  color: #666;
                  opacity: 0;
                  transition: all 0.3s ease;
                  transform: translate(50%, 0); }
                aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li a:hover {
                  color: #666; }
                  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li a:hover:before {
                    opacity: 1;
                    transform: translate(0, 0); }
  aside.app-sidebar .sidebar-footer {
    width: 180px;
    padding: 0;
    position: relative;
    box-shadow: 0 -1px 2px #dfe6e8; }
    aside.app-sidebar .sidebar-footer ul.menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: start;
          align-items: flex-start;
      -ms-flex-pack: center;
          justify-content: center; }
      aside.app-sidebar .sidebar-footer ul.menu > li > a {
        padding: 15px 8px;
        font-size: 1em;
        display: block;
        color: #c8d1d3; }
        aside.app-sidebar .sidebar-footer ul.menu > li > a .flag-icon {
          border-radius: 50%;
          height: 15px;
          width: 15px;
          box-shadow: none; }
      aside.app-sidebar .sidebar-footer ul.menu > li.dropdown {
        position: static !important; }
        aside.app-sidebar .sidebar-footer ul.menu > li.dropdown > a {
          position: relative; }
        aside.app-sidebar .sidebar-footer ul.menu > li.dropdown .dropdown-menu {
          width: 100%;
          left: 0;
          padding: 0;
          top: auto;
          bottom: 100%;
          z-index: 100113;
          border-radius: 2px;
          border: 0;
          box-shadow: none; }
          aside.app-sidebar .sidebar-footer ul.menu > li.dropdown .dropdown-menu > li {
            border: 0;
            border-radius: 0;
            padding: 0;
            margin-bottom: 0;
            font-weight: 300;
            border-bottom: 1px solid rgba(200, 209, 211, 0.2);
            font-size: 0.9em;
            background-color: #FFF; }
            aside.app-sidebar .sidebar-footer ul.menu > li.dropdown .dropdown-menu > li a {
              display: block;
              text-decoration: none;
              color: #666;
              padding: 0.9rem 1.5rem; }
              aside.app-sidebar .sidebar-footer ul.menu > li.dropdown .dropdown-menu > li a:hover {
                background-color: #FFF;
                color: #18aa4a; }
  aside.app-sidebar.active {
    transform: translate(0, 0); }

@media (min-width: 767.01px) {
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: translate(-40px, -50%); } }

@media (max-width: 767px) {
  aside.app-sidebar {
    transform: translate(-100%, 0);
    box-shadow: none;
    width: 100%; }
    aside.app-sidebar .sidebar-header, aside.app-sidebar .sidebar-menu, aside.app-sidebar .sidebar-footer {
      width: 100%; }
    aside.app-sidebar .sidebar-header {
      height: 70px;
      border-bottom: 1px solid #f0f4f5; }
      aside.app-sidebar .sidebar-header .sidebar-brand {
        height: 70px; }
      aside.app-sidebar .sidebar-header .sidebar-toggle {
        display: block; }
    aside.app-sidebar .sidebar-menu {
      position: relative;
      overflow: auto; }
      aside.app-sidebar .sidebar-menu ul.sidebar-nav {
        padding: 0; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: start;
              justify-content: flex-start;
          padding: 15px;
          border-bottom: 1px solid #f0f4f5; }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .icon {
            margin: 0;
            background-color: transparent !important;
            border-radius: 0;
            margin-right: 15px;
            width: 40px;
            height: 40px; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-toggle:before {
          display: -ms-flexbox;
          display: flex; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu {
          display: none;
          visibility: visible;
          opacity: 1;
          width: 100%;
          left: 0;
          position: relative;
          transform: translate(0, 0);
          margin-top: 0;
          border-radius: 0;
          border: 0;
          box-shadow: none;
          padding: 0;
          background-color: rgba(223, 230, 232, 0.2); }
          aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul {
            padding: 0; }
            aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li {
              border-bottom: 1px solid rgba(223, 230, 232, 0.5); }
              aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li a {
                padding: 15px;
                padding-left: 70px; }
              aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li.section {
                padding: 15px;
                padding-left: 70px; }
                aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li.section > i {
                  left: 15px;
                  margin-right: 15px;
                  width: 40px;
                  height: 100%; }
              aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown .dropdown-menu > ul > li.line {
                display: none; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown.open .dropdown-toggle:before {
          content: "\f106"; }
        aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown.open .dropdown-menu {
          display: block; }
    aside.app-sidebar.active {
      transform: translate(0, 0); }
  .app-container.__sidebar {
    overflow: hidden;
    height: 100vh; }
    .app-container.__sidebar .app-container {
      height: 100vh;
      overflow: hidden; } }


aside.app-sidebar {
  background-color: #FFF; }
  aside.app-sidebar .sidebar-header .sidebar-brand .highlight {
    background-color: #29c75f; }
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .icon {
    background-color: rgba(141, 146, 147, 0.15);
    color: rgba(141, 146, 147, 0.45); }
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li > a .title {
    color: #8d9293; }
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.active > a .icon {
    background-color: rgba(41, 199, 95, 0.25);
    color: rgba(41, 199, 95, 0.75); }
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.active > a .title {
    color: #29c75f; }
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown > .dropdown-menu > ul > li.section {
    color: #29c75f; }
  aside.app-sidebar .sidebar-menu ul.sidebar-nav > li.dropdown.open > a:after {
    border-right-color: #FFF; }
  aside.app-sidebar .sidebar-footer {
    border-top-color: rgba(0, 0, 0, 0.1);
    background-color: #FFF; }
    aside.app-sidebar .sidebar-footer ul.menu > li.dropdown .dropdown-menu {
      border-bottom-color: #29c75f; }

.app-login {
  background: #29c75f;
  padding: 0 !important; }
  .app-login .app-body {
    position: relative;
    display: -ms-flexbox;
    display: flex; }
    .app-login .app-body .app-block {
      transition: all 0.2s ease;
      background-color: #FFF;
      border-radius: 2px;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
      .app-login .app-body .app-block .app-right-section {
        background-color: #18aa4a;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        height: 100%;
        position: relative;
        padding: 4rem 6rem; }
        .app-login .app-body .app-block .app-right-section .app-brand {
          font-size: 1.5em;
          font-weight: 200;
          color: #FFF;
          padding-bottom: 4rem;
          margin-bottom: 4rem;
          border-bottom: 1px solid rgba(15, 121, 51, 0.25); }
          .app-login .app-body .app-block .app-right-section .app-brand .highlight {
            color: #18aa4a;
            background-color: white;
            border-radius: 2px;
            padding: 2px 8px;
            font-weight: 200; }
        .app-login .app-body .app-block .app-right-section .app-info ul.list {
          list-style: none;
          padding: 0;
          margin: 0; }
          .app-login .app-body .app-block .app-right-section .app-info ul.list li {
            margin-bottom: 2rem;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: start;
                justify-content: flex-start; }
            .app-login .app-body .app-block .app-right-section .app-info ul.list li .icon {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: row;
                  flex-direction: row;
              -ms-flex-wrap: nowrap;
                  flex-wrap: nowrap;
              -ms-flex-align: center;
                  align-items: center;
              -ms-flex-pack: center;
                  justify-content: center;
              display: block;
              margin: 0 auto;
              background-color: #FFF;
              color: #18aa4a;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              transition: all 0.2s ease; }
              .app-login .app-body .app-block .app-right-section .app-info ul.list li .icon i.fa, .app-login .app-body .app-block .app-right-section .app-info ul.list li .icon i.ion {
                width: 100%;
                height: 100%;
                font-size: 2em;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: row;
                    flex-direction: row;
                -ms-flex-wrap: nowrap;
                    flex-wrap: nowrap;
                -ms-flex-align: center;
                    align-items: center;
                -ms-flex-pack: center;
                    justify-content: center;
                transition: all 0.2s ease; }
            .app-login .app-body .app-block .app-right-section .app-info ul.list li .title {
              -ms-flex: 1;
                  flex: 1;
              font-weight: 300;
              padding: 0 1rem;
              font-size: 0.9em;
              color: #FFF; }
      .app-login .app-body .app-block .app-form {
        padding: 4rem;
        position: relative;
        -ms-flex: 1;
            flex: 1; }
        .app-login .app-body .app-block .app-form .form-header {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: center;
              justify-content: center;
          font-size: 2em;
          font-weight: 200;
          color: #444;
          margin-bottom: 4rem; }
          .app-login .app-body .app-block .app-form .form-header .highlight {
            font-weight: 400;
            color: #29c75f;
            margin-right: 2px; }
        .app-login .app-body .app-block .app-form .form-suggestion {
          margin-bottom: 15px; }
        .app-login .app-body .app-block .app-form form {
          min-width: 280px; }
          .app-login .app-body .app-block .app-form form .btn-submit {
            width: 100%; }
        .app-login .app-body .app-block .app-form .form-line {
          width: 100%;
          position: relative;
          text-align: center;
          font-size: 0.9em;
          z-index: 2;
          margin-top: 20px;
          margin-bottom: 20px;
          color: #8d9293; }
          .app-login .app-body .app-block .app-form .form-line .title {
            background-color: #FFF;
            position: relative;
            z-index: 2;
            width: 50px;
            margin: 0 auto; }
          .app-login .app-body .app-block .app-form .form-line:after {
            content: '';
            position: absolute;
            width: 50%;
            bottom: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 1;
            border-bottom: 1px solid #dfe6e8; }
        .app-login .app-body .app-block .app-form .form-footer {
          text-align: center; }
          .app-login .app-body .app-block .app-form .form-footer .btn {
            margin: 0 auto; }
  .app-login.__loading .app-block {
    filter: url("https://almanshurin.com/assets/images/blur.svg#blur");
    -webkit-filter: blur(2px);
    filter: blur(2px);
    transform: scale(0.8); }
  .app-login.__loading .loader-container {
    color: #FFF;
    font-size: 2em;
    font-weight: 200; }
    .app-login.__loading .loader-container .sk-folding-cube .sk-cube:before {
      background-color: #FFF; }
  .app-login.__loading:after {
    background-color: rgba(41, 199, 95, 0.75); }

ul.icons {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
  overflow: auto; }
  ul.icons > li {
    float: left;
    width: 100px;
    height: 100px;
    border: 1px solid #e7edee;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 0.8em; }
    ul.icons > li .fa {
      font-size: 2.5em;
      padding: 15px; }
    ul.icons > li div {
      display: block; }

@media (max-width: 767px) {
  ul.icons {
    margin-bottom: 15px; }
    ul.icons > li {
      width: 60px;
      height: 60px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
      ul.icons > li .fa {
        font-size: 1.5em;
        padding: 0; }
      ul.icons > li div {
        display: none; } }

.app-inbox .messaging {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  height: 100%;
  width: 100%; }
  .app-inbox .messaging > .heading {
    padding: 20px;
    width: 100%;
    background-color: #FFF;
    color: #8d9293;
    border-bottom: 1px solid #dfe6e8; }
    .app-inbox .messaging > .heading .badge {
      margin-left: 10px;
      font-size: 0.7em; }
      .app-inbox .messaging > .heading .badge.badge-success {
        background-color: #29c75f; }
  .app-inbox .messaging ul.chat {
    -ms-flex: 1;
        flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    background-color: #FFF;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: auto; }
    .app-inbox .messaging ul.chat > li {
      padding: 20px;
      padding-top: 0;
      padding-right: 20%; }
      .app-inbox .messaging ul.chat > li .message {
        background-color: #e7edee;
        border-radius: 3px;
        padding: 15px; }
      .app-inbox .messaging ul.chat > li .info {
        padding: 5px 0;
        font-size: 0.85em;
        color: #8d9293;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: start;
            align-items: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
        .app-inbox .messaging ul.chat > li .info > * {
          margin-right: 10px; }
      .app-inbox .messaging ul.chat > li.right {
        -ms-flex-item-align: end;
            align-self: flex-end;
        padding-left: 20%;
        padding-right: 20px;
        text-align: right; }
        .app-inbox .messaging ul.chat > li.right .message {
          text-align: left; }
        .app-inbox .messaging ul.chat > li.right .info {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: start;
              align-items: flex-start;
          -ms-flex-pack: end;
              justify-content: flex-end; }
          .app-inbox .messaging ul.chat > li.right .info > * {
            margin-left: 10px;
            margin-right: 0px; }
      .app-inbox .messaging ul.chat > li.line {
        width: 100%;
        position: relative;
        text-align: center;
        font-size: 0.9em;
        z-index: 2;
        padding-right: 20px;
        padding-top: 20px;
        color: #8d9293; }
        .app-inbox .messaging ul.chat > li.line .title {
          background-color: #FFF;
          position: relative;
          z-index: 2;
          width: 140px;
          margin: 0 auto; }
        .app-inbox .messaging ul.chat > li.line:after {
          content: '';
          position: absolute;
          width: 50%;
          bottom: 50%;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 1;
          border-bottom: 1px solid #dfe6e8; }

.app-inbox .chat-group {
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .app-inbox .chat-group .heading {
    width: 100%;
    padding: 20px;
    background-color: #FFF;
    color: #8d9293;
    border-right: 1px solid #dfe6e8;
    border-bottom: 1px solid #dfe6e8; }
  .app-inbox .chat-group ul.group {
    background-color: #FFF;
    -ms-flex: 1;
        flex: 1;
    width: 100%;
    box-shadow: 0 2px 2px rgba(68, 68, 68, 0.2);
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    border-right: 1px solid #dfe6e8;
    overflow: auto; }
    .app-inbox .chat-group ul.group > li {
      padding: 20px; }
      .app-inbox .chat-group ul.group > li.section {
        padding: 6px 20px;
        background-color: #e7edee;
        border-bottom: 1px solid #dfe6e8;
        color: #8d9293;
        font-size: 0.9em; }
      .app-inbox .chat-group ul.group > li.message {
        padding: 0;
        border-bottom: 1px solid #dfe6e8; }
        .app-inbox .chat-group ul.group > li.message a {
          display: block;
          text-decoration: none;
          color: #444;
          padding: 20px; }
          .app-inbox .chat-group ul.group > li.message a .message {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: center;
                align-items: center;
            -ms-flex-pack: start;
                justify-content: flex-start; }
            .app-inbox .chat-group ul.group > li.message a .message .content {
              -ms-flex: 1;
                  flex: 1; }
              .app-inbox .chat-group ul.group > li.message a .message .content .description {
                font-size: 0.8em;
                opacity: 0.4; }
            .app-inbox .chat-group ul.group > li.message a .message .profile {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 8px; }
        .app-inbox .chat-group ul.group > li.message span.badge {
          border-radius: 3px;
          font-weight: 400;
          font-weight: 0.8em; }

.app-messaging-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding: 0 30px;
  margin-bottom: 30px;
  -ms-flex: 1;
      flex: 1; }

ui-view > .app-messaging-container {
  padding: 0;
  width: 100%;
  margin-bottom: 0; }

.app-messaging {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex: 1;
      flex: 1;
  width: 100%;
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: 0 1px 2px #c8d1d3; }
  .app-messaging.collapse.in {
    display: -ms-flexbox;
    display: flex; }
  .app-messaging .messaging {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    height: 100%;
    width: auto;
    min-width: auto;
    -ms-flex: 1;
        flex: 1;
    transition: all 0.3s ease; }
    .app-messaging .messaging > .heading {
      padding: 0 20px;
      height: 60px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start;
      width: 100%;
      color: #8d9293;
      border-bottom: 1px solid #dfe6e8; }
      .app-messaging .messaging > .heading .title {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: start;
            justify-content: flex-start; }
        .app-messaging .messaging > .heading .title .btn-back {
          font-size: 2em;
          color: #444;
          display: inline-block;
          text-decoration: none;
          width: 30px;
          text-align: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: start;
              justify-content: flex-start; }
        .app-messaging .messaging > .heading .title .badge {
          margin-left: 10px;
          font-size: 0.7em; }
    .app-messaging .messaging > .footer {
      width: 100%;
      padding: 8px;
      background-color: #dfe6e8; }
      .app-messaging .messaging > .footer .message-box {
        background-color: #FFF;
        border-radius: 2px;
        box-shadow: 0 1px 1px #c8d1d3;
        width: 100%;
        height: 80px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: start;
            align-items: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
        border-top: 1px solid #dfe6e8; }
        .app-messaging .messaging > .footer .message-box textarea, .app-messaging .messaging > .footer .message-box button {
          margin-bottom: 0;
          border: 0;
          height: 100%;
          border-radius: 0; }
        .app-messaging .messaging > .footer .message-box textarea {
          -ms-flex: 1;
              flex: 1; }
        .app-messaging .messaging > .footer .message-box button {
          border-left: 1px solid #dfe6e8;
          color: #29c75f; }
          .app-messaging .messaging > .footer .message-box button .fa {
            margin-right: 1rem; }
    .app-messaging .messaging ul.chat {
      -ms-flex: 1;
          flex: 1;
      min-width: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: start;
          align-items: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
      background-color: #FFF;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: auto;
      position: relative; }
      .app-messaging .messaging ul.chat > li {
        width: 100%;
        padding: 0 20px;
        padding-right: 20%;
        margin-bottom: 10px; }
        .app-messaging .messaging ul.chat > li .message {
          background-color: #e7edee;
          border-radius: 3px;
          padding: 15px; }
        .app-messaging .messaging ul.chat > li .info {
          padding: 5px 0;
          font-size: 0.85em;
          color: #8d9293;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: row;
              flex-direction: row;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
          -ms-flex-align: start;
              align-items: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
          .app-messaging .messaging ul.chat > li .info > * {
            margin-right: 10px; }
        .app-messaging .messaging ul.chat > li.right {
          -ms-flex-item-align: end;
              align-self: flex-end;
          padding-left: 20%;
          padding-right: 20px;
          text-align: right; }
          .app-messaging .messaging ul.chat > li.right .message {
            text-align: left; }
          .app-messaging .messaging ul.chat > li.right .info {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
                flex-direction: row;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
            -ms-flex-align: start;
                align-items: flex-start;
            -ms-flex-pack: end;
                justify-content: flex-end; }
            .app-messaging .messaging ul.chat > li.right .info > * {
              margin-left: 10px;
              margin-right: 0px; }
        .app-messaging .messaging ul.chat > li.line {
          width: 100%;
          position: relative;
          text-align: center;
          font-size: 0.9em;
          z-index: 2;
          padding-right: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          color: #8d9293; }
          .app-messaging .messaging ul.chat > li.line .title {
            background-color: #FFF;
            position: relative;
            z-index: 2;
            width: 140px;
            margin: 0 auto; }
          .app-messaging .messaging ul.chat > li.line:after {
            content: '';
            position: absolute;
            width: 50%;
            bottom: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 1;
            border-bottom: 1px solid #dfe6e8; }
  .app-messaging .chat-group {
    height: 100% !important;
    width: 300px;
    min-width: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    transition: all 0.3s ease; }
    .app-messaging .chat-group .heading {
      width: 100%;
      padding: 0 20px;
      height: 60px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start;
      color: #8d9293;
      border-right: 1px solid #dfe6e8;
      border-bottom: 1px solid #dfe6e8; }
    .app-messaging .chat-group ul.group {
      background-color: #FFF;
      -ms-flex: 1;
          flex: 1;
      width: 100%;
      padding: 0;
      list-style: none;
      margin-bottom: 0;
      border-right: 1px solid #dfe6e8;
      overflow: auto;
      position: relative; }
      .app-messaging .chat-group ul.group > li {
        padding: 20px; }
        .app-messaging .chat-group ul.group > li.section {
          padding: 6px 20px;
          background-color: #e7edee;
          border-bottom: 1px solid #dfe6e8;
          color: #8d9293;
          font-size: 0.9em; }
        .app-messaging .chat-group ul.group > li.message {
          padding: 0;
          border-bottom: 1px solid #dfe6e8; }
          .app-messaging .chat-group ul.group > li.message a {
            display: block;
            text-decoration: none;
            color: #444;
            padding: 20px; }
            .app-messaging .chat-group ul.group > li.message a .message {
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: row;
                  flex-direction: row;
              -ms-flex-wrap: nowrap;
                  flex-wrap: nowrap;
              -ms-flex-align: center;
                  align-items: center;
              -ms-flex-pack: start;
                  justify-content: flex-start; }
              .app-messaging .chat-group ul.group > li.message a .message .content {
                -ms-flex: 1;
                    flex: 1; }
                .app-messaging .chat-group ul.group > li.message a .message .content .description {
                  font-size: 0.8em;
                  opacity: 0.4; }
              .app-messaging .chat-group ul.group > li.message a .message .profile {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 8px; }
          .app-messaging .chat-group ul.group > li.message span.badge {
            border-radius: 3px;
            font-weight: 400;
            font-weight: 0.8em; }

@media (max-width: 991px) {
  .app-messaging {
    border-radius: 0;
    overflow: hidden; }
    .app-messaging .chat-group, .app-messaging .messaging {
      min-width: 100%;
      width: 100%;
      -ms-flex: 1;
          flex: 1; }
    .app-messaging .chat-group {
      transform: translate(0, 0); }
    .app-messaging .messaging {
      transform: translate(100%, 0); }
    .app-messaging.collapse .chat-group {
      transform: translate(0, 0); }
    .app-messaging.collapse .messaging {
      transform: translate(100%, 0); }
    .app-messaging.collapse.in {
      display: -ms-flexbox;
      display: flex; }
      .app-messaging.collapse.in .chat-group {
        transform: translate(-100%, 0); }
      .app-messaging.collapse.in .messaging {
        transform: translate(-100%, 0); } }

@media (max-width: 767px) {
  .app-messaging-container {
    padding: 0;
    margin-bottom: 0; } }

.search-result {
  background-color: #FFF;
  border-radius: 3px; }
  .search-result > .footer {
    padding: 20px 30px; }
  .search-result > ul.result {
    list-style: none;
    padding: 0;
    margin: 0; }
    .search-result > ul.result > li {
      border-radius: 0px;
      border-bottom: 1px solid #e7edee;
      padding: 20px 30px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: start;
          align-items: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
      .search-result > ul.result > li .img {
        margin-right: 20px;
        margin-bottom: 0; }
        .search-result > ul.result > li .img img {
          max-width: 200px;
          height: auto; }
      .search-result > ul.result > li .info a {
        color: #666; }
      .search-result > ul.result > li .info .title {
        font-size: 1.2em;
        margin-bottom: 5px;
        color: #666; }
        .search-result > ul.result > li .info .title .highlight {
          color: #29c75f; }
      .search-result > ul.result > li .info .description {
        color: #8d9293;
        font-size: 0.9em; }

@media (max-width: 767px) {
  .search-result > .footer {
    padding: 15px; }
  .search-result > ul.result > li {
    padding: 15px;
    -ms-flex-direction: column;
        flex-direction: column; }
    .search-result > ul.result > li .img {
      margin-bottom: 15px;
      margin-right: 0; }
      .search-result > ul.result > li .img img {
        max-width: 100%; } }

.badge {
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 400;
  padding: 6px;
  background-color: #8d9293; }
  .badge.badge-icon {
    padding: 0; }
    .badge.badge-icon i {
      padding: 6px; }
    .badge.badge-icon span {
      padding: 6px; }
  .badge.badge-primary {
    color: #095077;
    background-color: rgba(9, 80, 119, 0.2); }
    .badge.badge-primary.badge-icon i {
      background-color: rgba(9, 80, 119, 0.1); }
  .badge.badge-danger {
    color: #E74C3C;
    background-color: rgba(231, 76, 60, 0.2); }
    .badge.badge-danger.badge-icon i {
      background-color: rgba(231, 76, 60, 0.1); }
  .badge.badge-warning {
    color: #fc8229;
    background-color: rgba(252, 130, 41, 0.2); }
    .badge.badge-warning.badge-icon i {
      background-color: rgba(252, 130, 41, 0.1); }
  .badge.badge-info {
    color: #20a3b9;
    background-color: rgba(32, 163, 185, 0.2); }
    .badge.badge-info.badge-icon i {
      background-color: rgba(32, 163, 185, 0.1); }
  .badge.badge-success {
    color: #29c75f;
    background-color: rgba(41, 199, 95, 0.2); }
    .badge.badge-success.badge-icon i {
      background-color: rgba(41, 199, 95, 0.1); }

.card {
  width: 100%;
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: 0 1px 2px #c8d1d3; }
  .card .card-header {
    padding: 30px;
    font-size: 1.1em;
    font-weight: 400;
    border-bottom: 1px solid #dfe6e8;
    border-left: 0px solid transparent;
    color: #666;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .card .card-header .card-title {
      -ms-flex: 1;
          flex: 1; }
    .card .card-header .card-action {
      list-style: none;
      margin: 0;
      padding: 0; }
      .card .card-header .card-action > li > a {
        color: #dfe6e8; }
      .card .card-header .card-action > li > .dropdown-menu {
        right: 0;
        left: auto;
        border-radius: 2px; }
  .card .card-body {
    padding: 30px; }
  .card.card-mini .card-header {
    padding: 20px 30px; }
  .card.card-mini .card-body {
    padding: 20px 30px; }
  .card.card-tab .card-header {
    padding: 0;
    background-color: #f0f4f5;
    border-bottom: 0;
    overflow-x: scroll;
    overflow-y: visible; }
  .card.card-tab .card-header > ul, .card.card-tab ul.nav-tabs {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    width: 100%;
    font-weight: 400;
    border-bottom: 1px solid #dfe6e8; }
    .card.card-tab .card-header > ul > li, .card.card-tab ul.nav-tabs > li {
      display: block;
      margin-bottom: -2px;
      z-index: 10;
      width: 140px;
      min-width: 140px; }
      .card.card-tab .card-header > ul > li a, .card.card-tab ul.nav-tabs > li a {
        padding: 20px 30px;
        width: 100%;
        text-align: center;
        display: block;
        text-decoration: none;
        color: #8d9293;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .card.card-tab .card-header > ul > li.active, .card.card-tab ul.nav-tabs > li.active {
        border-left: 1px solid #dfe6e8;
        border-right: 1px solid #dfe6e8;
        background-color: #FFF; }
        .card.card-tab .card-header > ul > li.active a, .card.card-tab ul.nav-tabs > li.active a {
          color: #29c75f;
          border-bottom: none !important; }
      .card.card-tab .card-header > ul > li:first-child.active, .card.card-tab ul.nav-tabs > li:first-child.active {
        border-left: 0; }
  .card.card-tab .tab-content {
    padding: 0;
    position: relative;
    transition: all 0.3s ease; }
    .card.card-tab .tab-content .tab-pane {
      position: absolute;
      top: auto;
      left: auto;
      bottom: auto;
      right: auto;
      width: 100%;
      height: auto;
      display: none;
      padding: 30px;
      transition: all 0.3s ease;
      opacity: 0; }
      .card.card-tab .tab-content .tab-pane.active {
        transform: translate(0, 0);
        position: relative;
        opacity: 1;
        display: block; }
  .card.card-tab > .ng-isolate-scope > .nav-tabs {
    background-color: #f0f4f5; }
    .card.card-tab > .ng-isolate-scope > .nav-tabs > li.active {
      border-bottom: 2px solid #FFF;
      border-left: 1px solid #dfe6e8;
      border-right: 1px solid #dfe6e8;
      background-color: #FFF; }
      .card.card-tab > .ng-isolate-scope > .nav-tabs > li.active a {
        color: #29c75f; }
  .card.card-tab > .ng-isolate-scope .tab-content .tab-pane {
    opacity: 0; }
    .card.card-tab > .ng-isolate-scope .tab-content .tab-pane.active {
      opacity: 1; }
  .card.card-tab.card-mini .card-header > li a, .card.card-tab.card-mini ul.nav-tabs > li a {
    padding-top: 20px;
    padding-bottom: 20px; }
  .card.card-banner {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 2px;
    transition: all 0.2s ease; }
    .card.card-banner:hover {
      cursor: pointer;
      text-decoration: none; }
    .card.card-banner .card-header {
      background-color: #FFF; }
    .card.card-banner .card-body {
      padding: 0px; }
      .card.card-banner .card-body .icon {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 4em;
        color: #444;
        z-index: 0;
        padding: 10px;
        min-height: 100%;
        min-width: 100px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center; }
      .card.card-banner .card-body .content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: end;
            align-items: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
        position: relative;
        width: 100%;
        padding: 2rem;
        z-index: 100; }
        .card.card-banner .card-body .content .value {
          font-size: 4em;
          line-height: 3rem;
          font-weight: 200;
          padding-top: 2rem;
          color: #444; }
          .card.card-banner .card-body .content .value .sign {
            font-size: 0.4em;
            font-weight: 200;
            margin-right: 5px;
            opacity: 0.75; }
        .card.card-banner .card-body .content .title {
          font-size: 1em;
          font-weight: 400;
          color: #8d9293;
          text-transform: uppercase; }
      .card.card-banner .card-body::after {
        content: '';
        position: relative;
        display: block;
        clear: both; }
  .card.card-chart .card-header {
    border-bottom: 0; }
    .card.card-chart .card-header .card-title .title {
      background-color: #FFF;
      padding: 6px 12px;
      border-radius: 2px;
      font-weight: 400;
      width: auto;
      display: inline;
      text-transform: uppercase; }
  .card.card-green {
    background-color: #29c75f; }
    .card.card-green .card-body .icon {
      color: #FFF;
      background-color: rgba(255, 255, 255, 0.05); }
    .card.card-green .card-body .content .value {
      color: #FFF; }
    .card.card-green-light {
      background-color: #FFF; }
      .card.card-green-light .card-body .icon {
        color: #29c75f;
        background-color: rgba(41, 199, 95, 0.05); }
      .card.card-green-light .card-body .content .value {
        color: #29c75f; }
      .card.card-green-light:hover {
        background-color: #29c75f; }
        .card.card-green-light:hover .card-body .icon {
          color: #FFF;
          background-color: rgba(255, 255, 255, 0.1); }
        .card.card-green-light:hover .card-body .content .title, .card.card-green-light:hover .card-body .content .value {
          color: #FFF; }
    .card.card-green.card-chart .card-header {
      background-color: #29c75f;
      color: #29c75f; }
  .card.card-blue {
    background-color: #39c3da; }
    .card.card-blue .card-body .icon {
      color: #FFF;
      background-color: rgba(255, 255, 255, 0.05); }
    .card.card-blue .card-body .content .value {
      color: #FFF; }
    .card.card-blue-light {
      background-color: #FFF; }
      .card.card-blue-light .card-body .icon {
        color: #39c3da;
        background-color: rgba(57, 195, 218, 0.05); }
      .card.card-blue-light .card-body .content .value {
        color: #39c3da; }
      .card.card-blue-light:hover {
        background-color: #39c3da; }
        .card.card-blue-light:hover .card-body .icon {
          color: #FFF;
          background-color: rgba(255, 255, 255, 0.1); }
        .card.card-blue-light:hover .card-body .content .title, .card.card-blue-light:hover .card-body .content .value {
          color: #FFF; }
    .card.card-blue.card-chart .card-header {
      background-color: #39c3da;
      color: #39c3da; }
  .card.card-orange {
    background-color: #fc8229; }
    .card.card-orange .card-body .icon {
      color: #FFF;
      background-color: rgba(255, 255, 255, 0.05); }
    .card.card-orange .card-body .content .value {
      color: #FFF; }
    .card.card-orange-light {
      background-color: #FFF; }
      .card.card-orange-light .card-body .icon {
        color: #fc8229;
        background-color: rgba(252, 130, 41, 0.05); }
      .card.card-orange-light .card-body .content .value {
        color: #fc8229; }
      .card.card-orange-light:hover {
        background-color: #fc8229; }
        .card.card-orange-light:hover .card-body .icon {
          color: #FFF;
          background-color: rgba(255, 255, 255, 0.1); }
        .card.card-orange-light:hover .card-body .content .title, .card.card-orange-light:hover .card-body .content .value {
          color: #FFF; }
    .card.card-orange.card-chart .card-header {
      background-color: #fc8229;
      color: #fc8229; }
  .card.card-yellow {
    background-color: #FFBC11; }
    .card.card-yellow .card-body .icon {
      color: #FFF;
      background-color: rgba(255, 255, 255, 0.05); }
    .card.card-yellow .card-body .content .value {
      color: #FFF; }
    .card.card-yellow-light {
      background-color: #FFF; }
      .card.card-yellow-light .card-body .icon {
        color: #FFBC11;
        background-color: rgba(255, 188, 17, 0.05); }
      .card.card-yellow-light .card-body .content .value {
        color: #FFBC11; }
      .card.card-yellow-light:hover {
        background-color: #FFBC11; }
        .card.card-yellow-light:hover .card-body .icon {
          color: #FFF;
          background-color: rgba(255, 255, 255, 0.1); }
        .card.card-yellow-light:hover .card-body .content .title, .card.card-yellow-light:hover .card-body .content .value {
          color: #FFF; }
    .card.card-yellow.card-chart .card-header {
      background-color: #FFBC11;
      color: #FFBC11; }

@media (max-width: 767px) {
  .card .card-header {
    padding: 15px; }
  .card .card-body {
    padding: 15px; }
  .card.card-mini .card-header {
    padding: 15px; }
  .card.card-mini .card-body {
    padding: 15px; }
  .card.card-tab .card-header {
    padding: 0; }
  .card.card-tab .card-body {
    padding: 0; }
  .card.card-tab .tab-content .tab-pane {
    padding: 15px; }
  .card.card-tab.card-mini .card-header > li a, .card.card-tab.card-mini ul.nav-tabs > li a {
    padding-top: 15px;
    padding-bottom: 15px; }
  .card.card-banner .card-body .icon {
    font-size: 3em;
    min-width: 80px; }
  .card.card-banner .card-body .content {
    padding: 1rem; }
    .card.card-banner .card-body .content .value {
      font-size: 3em;
      padding-top: 1rem; }
    .card.card-banner .card-body .content .title {
      font-size: 0.9em; } }

.btn {
  padding: 10px 30px;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(223, 230, 232, 0.3);
  margin-bottom: 5px;
  transition: all .3s ease; }
  .btn:hover {
    transform: translate(0, -2px);
    box-shadow: 0 4px 6px rgba(223, 230, 232, 0.15); }
  .btn.btn-xs {
    padding: 5px 10px;
    border-bottom-width: 2px; }
  .btn.btn-sm {
    padding: 10px 20px;
    border-bottom-width: 2px; }
  .btn.btn-lg {
    font-size: 1.5em; }
  .btn.btn-default {
    border-color: #e7edee;
    background-color: #FFF; }
  .btn.btn-link {
    box-shadow: none;
    background-color: transparent;
    border-bottom-width: 0px; }
  .btn.btn-primary {
    border-color: #095077;
    border-bottom-color: #043D5D;
    background-color: #095077;
    box-shadow: 0 2px 3px rgba(9, 80, 119, 0.3); }
    .btn.btn-primary:hover {
      box-shadow: 0 4px 6px rgba(9, 80, 119, 0.15); }
  .btn.btn-success {
    border-color: #29c75f;
    border-bottom-color: #18aa4a;
    background-color: #29c75f;
    box-shadow: 0 2px 3px rgba(41, 199, 95, 0.3); }
    .btn.btn-success:hover {
      box-shadow: 0 4px 6px rgba(41, 199, 95, 0.15); }
  .btn.btn-warning {
    border-color: #fc8229;
    border-bottom-color: #eb6b0e;
    background-color: #fc8229;
    box-shadow: 0 2px 3px rgba(252, 130, 41, 0.3); }
    .btn.btn-warning:hover {
      box-shadow: 0 4px 6px rgba(252, 130, 41, 0.15); }
  .btn.btn-danger {
    border-color: #E74C3C;
    border-bottom-color: #d73727;
    background-color: #E74C3C;
    box-shadow: 0 2px 3px rgba(231, 76, 60, 0.3); }
    .btn.btn-danger:hover {
      box-shadow: 0 4px 6px rgba(231, 76, 60, 0.15); }
  .btn.btn-info {
    border-color: #39c3da;
    border-bottom-color: #20a3b9;
    background-color: #39c3da;
    box-shadow: 0 2px 3px rgba(57, 195, 218, 0.3); }
    .btn.btn-info:hover {
      box-shadow: 0 4px 6px rgba(57, 195, 218, 0.15); }
  .btn.btn-social {
    color: #FFF; }
    .btn.btn-social .info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start; }
    .btn.btn-social .icon {
      font-size: 2em;
      margin-right: 15px; }
    .btn.btn-social.__facebook {
      border-color: #3D62B3;
      border-bottom-color: #335795;
      background-color: #3D62B3; }
    .btn.btn-social.__twitter {
      border-color: #03ABEA;
      border-bottom-color: #029ad3;
      background-color: #03ABEA; }

input.form-control, textarea.form-control {
  border-radius: 2px;
  padding: 10px 15px;
  height: auto;
  font-size: 1em;
  line-height: auto;
  border: 1px solid #c8d1d3;
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 15px; }

textarea.form-control {
  padding: 15px; }

input ::-webkit-input-placeholder {
  line-height: normal; }

.input-group {
  border: 1px solid #c8d1d3;
  border-radius: 2px;
  margin-bottom: 15px;
  width: 100%; }
  .input-group .input-group-addon {
    font-size: 1em;
    width: auto;
    border: 0;
    background-color: transparent;
    width: 40px;
    text-align: center; }
  .input-group input.form-control, .input-group textarea.form-control {
    border: 0; }
  .input-group .input-group-addon + .form-control {
    border-left: 1px solid #dfe6e8; }

.form-horizontal .control-label {
  font-weight: 400;
  font-size: 1em;
  text-align: left; }

.form-horizontal .control-label-help {
  color: #8d9293;
  font-size: 0.9em; }

.form-horizontal .form-group .help-block {
  font-weight: 300;
  font-size: 0.9em; }

.form-group {
  margin-bottom: 0; }

.has-success .form-control {
  border-color: #18aa4a;
  box-shadow: none; }

.has-warning .form-control {
  border-color: #e9aa3a;
  box-shadow: none; }

.has-error .form-control {
  border-color: #E74C3C;
  box-shadow: none; }

.select2-dropdown {
  border: 1px solid #DDD;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }
  .select2-dropdown .select2-results__option[aria-selected=true] {
    background-color: #F9F9F9; }
  .select2-dropdown .select2-results__option__highlighted[aria-selected] {
    background-color: #095077;
    color: #FFF; }

.checkbox, .radio {
  position: relative;
  margin-top: 10px !important;
  margin-bottom: 15px !important; }
  .checkbox input[type='checkbox'], .checkbox input[type='radio'], .radio input[type='checkbox'], .radio input[type='radio'] {
    position: absolute;
    left: 0;
    margin-left: 0;
    opacity: 0; }
  .checkbox label, .radio label {
    padding-left: 30px; }
    .checkbox label:before, .checkbox label:after, .radio label:before, .radio label:after {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translate(0, -50%);
      border-radius: 2px; }
    .checkbox label:before, .radio label:before {
      content: '';
      border: 1px solid #c8d1d3;
      border-bottom: 2px solid #c8d1d3; }
    .checkbox label:after, .radio label:after {
      content: '';
      font-family: FontAwesome;
      text-align: center;
      border-radius: 2px;
      color: #18aa4a;
      font-size: 0.95em;
      border: 1px solid transparent; }
  .checkbox input:checked + label::after, .radio input:checked + label::after {
    content: '\f00c';
    color: #FFF;
    background-color: #29c75f;
    border: 1px solid #29c75f;
    border-bottom: 2px solid #18aa4a; }
  .checkbox.checkbox-inline, .checkbox.radio-inline, .radio.checkbox-inline, .radio.radio-inline {
    padding-left: 0; }
  .checkbox + .checkbox-inline, .radio + .checkbox-inline {
    margin-top: 10px; }

.radio label:before, .radio label:after {
  border-radius: 50%;
  border-bottom-width: 1px; }

.radio label:after {
  width: 12px;
  height: 12px;
  left: 10px;
  transform: translate(-50%, -50%); }

.radio input:checked + label::after {
  content: '';
  background-color: #29c75f;
  border-bottom-width: 0; }

.radio + .radio-inline {
  margin-top: 10px; }

.form-horizontal .checkbox, .form-horizontal .radio {
  min-height: auto;
  padding-top: 0; }

.select2 {
  width: 100%;
  margin-bottom: 15px; }
  .select2 .select2-selection--single {
    border: 1px solid #c8d1d3;
    border-radius: 2px;
    height: auto; }
    .select2 .select2-selection--single .select2-selection__rendered {
      color: #666;
      padding: 10px 15px; }
    .select2 .select2-selection--single .select2-selection__arrow {
      height: auto;
      width: auto;
      right: 15px;
      top: 50%;
      transform: translate(-50%, 0); }

.select2-container .select2-search--dropdown {
  background-color: #e7edee;
  border-bottom: 1px solid #c8d1d3; }
  .select2-container .select2-search--dropdown .select2-search__field {
    border: 1px solid #dfe6e8; }

.select2-container .select2-dropdown .select2-results__option--highlighted {
  background-color: #29c75f; }

.select2-container .select2-dropdown .select2-results__option {
  padding: 10px 15px; }

.ui-select-container {
  margin-bottom: 15px; }
  .ui-select-container .ui-select-match .btn {
    border: 1px solid #c8d1d3;
    border-radius: 2px;
    height: auto;
    padding: 10px 15px;
    padding-right: 30px;
    margin-bottom: 0; }
    .ui-select-container .ui-select-match .btn .ui-select-match-text {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start; }
    .ui-select-container .ui-select-match .btn:hover {
      transform: translate(0, 0); }
  .ui-select-container input.form-control {
    margin-bottom: 0; }
  .ui-select-container .ui-select-dropdown {
    padding: 0;
    border-radius: 1px; }

.input-group .select2 {
  margin-bottom: 0;
  width: 100% !important; }
  .input-group .select2 .select2-selection--single {
    border: none;
    border-right: 1px solid #c8d1d3; }

.input-group .ui-select-container {
  margin-bottom: 0; }
  .input-group .ui-select-container .ui-select-match .btn {
    border-radius: 0;
    border: none;
    border-right: 1px solid #c8d1d3; }

.alert {
  padding: 15px 20px;
  margin-bottom: 15px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  font-weight: 400; }
  .alert .alert-link {
    text-decoration: underline;
    font-weight: 400; }

.alert-dismissable .close, .alert-dismissible .close {
  float: right;
  right: 0px; }

.alert-success {
  color: #0f7933;
  border-color: #9df0b9;
  background-color: #bafed1; }
  .alert-success .alert-link {
    color: #0f7933; }

.alert-info {
  color: #12606d;
  border-color: #93e5f3;
  background-color: #c3f0f7; }
  .alert-info .alert-link {
    color: #12606d; }

.alert-warning {
  color: #851b10;
  border-color: #fbd490;
  background-color: #ffe5b6; }
  .alert-warning .alert-link {
    color: #851b10; }

.alert-danger {
  color: #d73727;
  border-color: #f3b1ab;
  background-color: #fdc3bd; }
  .alert-danger .alert-link {
    color: #d73727; }

.progress {
  border-radius: 2px; }

.progress-bar-success {
  background-color: #29c75f; }

.progress-bar-info {
  background-color: #39c3da; }

.progress-bar-warning {
  background-color: #FFBC11; }

.progress-bar-danger {
  background-color: #E74C3C; }

.pagination {
  font-size: 14px;
  margin: 0; }
  .pagination > li > a, .pagination > li > span {
    color: #444;
    border-radius: 2px;
    border-color: #dfe6e8;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin-left: 2px; }
    .pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
      color: #444;
      border-radius: 2px;
      border-color: #dfe6e8;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      margin-left: 2px; }
  .pagination > li.active > a, .pagination > li.active > span {
    border: 0;
    border-bottom: 2px solid #18aa4a;
    background-color: #29c75f; }
    .pagination > li.active > a:hover, .pagination > li.active > a:focus, .pagination > li.active > span:hover, .pagination > li.active > span:focus {
      border: 0;
      border-bottom: 2px solid #18aa4a;
      background-color: #29c75f; }

table.table > tbody > tr td, table.table > tbody > tr th, table.table > thead > tr td, table.table > thead > tr th {
  font-size: 14px;
  padding: 15px 30px; }

table.table.card-table {
  margin-bottom: 0; }
  table.table.card-table > thead > tr > th {
    margin-left: 1px;
    font-size: 0.9em;
    background-color: #f0f4f5;
    border-bottom-color: #dfe6e8;
    color: #999;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom-width: 1px; }
  table.table.card-table > tbody > tr > td {
    border-top-color: #f0f4f5;
    color: #666; }

.card-mini table.table.card-table > tbody > tr td, .card-mini table.table.card-table > thead > tr td {
  padding: 15px 30px; }

.card-mini table.table.card-table > tbody > tr th, .card-mini table.table.card-table > thead > tr th {
  padding: 15px 30px; }

.table-responsive {
  border: 0;
  margin-bottom: 0; }

@media (max-width: 767px) {
  table.table > tbody > tr td, table.table > tbody > tr th, table.table > thead > tr td, table.table > thead > tr th {
    padding: 15px 15px; }
  .card-mini table.table.card-table > tbody > tr td, .card-mini table.table.card-table > thead > tr td {
    padding: 15px 15px; }
  .card-mini table.table.card-table > tbody > tr th, .card-mini table.table.card-table > thead > tr th {
    padding: 15px 15px; } }

.nav-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  border-radius: 2px;
  margin-bottom: 15px; }
  .nav-tabs > li {
    border: 0;
    margin-bottom: -2px; }
    .nav-tabs > li a, .nav-tabs > li a:focus, .nav-tabs > li a:hover {
      padding: 15px 30px;
      background-color: transparent;
      border: 0;
      color: #8d9293; }
    .nav-tabs > li.active a, .nav-tabs > li.active a:focus, .nav-tabs > li.active a:hover {
      border: 0;
      background-color: transparent;
      border-radius: 1px;
      border-bottom: 4px solid #29c75f;
      color: #29c75f; }

@media (max-width: 767px) {
  .nav-tabs {
    overflow-x: auto;
    overflow-y: hidden; }
    .nav-tabs > li a, .nav-tabs > li a:focus, .nav-tabs > li a:hover {
      padding: 15px; } }

.step .nav-tabs {
  border-bottom: 0px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;
  border-radius: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
  position: relative; }
  .step .nav-tabs > li {
    border-right: 0;
    z-index: 2;
    -ms-flex: 1;
        flex: 1;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden; }
    .step .nav-tabs > li[class^="col-"] {
      padding-left: 0;
      padding-right: 0;
      border-radius: 0; }
    .step .nav-tabs > li > a, .step .nav-tabs > li uib-tab-heading {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: start;
          justify-content: flex-start;
      border: 0;
      padding: 2rem;
      padding-left: 0;
      z-index: 2;
      color: #18aa4a; }
      .step .nav-tabs > li > a .icon, .step .nav-tabs > li uib-tab-heading .icon {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
            flex-direction: row;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
        font-size: 2.5em;
        margin-right: 0.5em;
        width: 60px;
        height: 60px;
        min-width: 60px;
        border-radius: 50%;
        background-color: #29c75f;
        color: #FFF; }
      .step .nav-tabs > li > a .heading, .step .nav-tabs > li uib-tab-heading .heading {
        -ms-flex: 1;
            flex: 1;
        text-align: left;
        overflow: hidden; }
        .step .nav-tabs > li > a .heading .title, .step .nav-tabs > li uib-tab-heading .heading .title {
          font-weight: bold;
          font-size: 1.1em;
          margin-bottom: 1rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .step .nav-tabs > li > a .heading .description, .step .nav-tabs > li uib-tab-heading .heading .description {
          font-size: 0.8em;
          margin-bottom: 0.5rem;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
      .step .nav-tabs > li > a:hover, .step .nav-tabs > li uib-tab-heading:hover {
        border: 0;
        background-color: transparent; }
    .step .nav-tabs > li.active > a, .step .nav-tabs > li.active > a:focus, .step .nav-tabs > li.active > a:hover, .step .nav-tabs > li.active uib-tab-heading {
      background-color: transparent;
      border: 0;
      color: #18aa4a; }
    .step .nav-tabs > li.active > a:hover {
      cursor: pointer; }
    .step .nav-tabs > li.active ~ li > a, .step .nav-tabs > li.active ~ li > a:focus, .step .nav-tabs > li.active ~ li > a:hover, .step .nav-tabs > li.active ~ li uib-tab-heading {
      color: #8d9293; }
      .step .nav-tabs > li.active ~ li > a .icon, .step .nav-tabs > li.active ~ li > a:focus .icon, .step .nav-tabs > li.active ~ li > a:hover .icon, .step .nav-tabs > li.active ~ li uib-tab-heading .icon {
        background-color: #c8d1d3;
        color: #FFF; }
    .step .nav-tabs > li.active ~ li > a, .step .nav-tabs > li.active ~ li > a:focus, .step .nav-tabs > li.active ~ li > a:hover, .step .nav-tabs > li.active ~ li uib-tab-heading {
      color: #8d9293; }
    .step .nav-tabs > li.active ~ li:after {
      content: '';
      z-index: 1;
      position: absolute;
      display: block;
      height: 4px;
      border-radius: 0px;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      background-color: #dfe6e8; }
    .step .nav-tabs > li:last-child {
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px; }
      .step .nav-tabs > li:last-child:before, .step .nav-tabs > li:last-child:after {
        display: none; }
  .step .nav-tabs:after {
    content: '';
    z-index: 1;
    position: absolute;
    display: block;
    height: 4px;
    border-radius: 2px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 100%;
    background-color: #29c75f; }

.step .tab-content > div {
  padding: 0; }

@media (max-width: 767px) {
  .step .nav-tabs > li > a, .step .nav-tabs > li uib-tab-heading {
    -ms-flex-pack: center;
        justify-content: center;
    padding: 0; }
    .step .nav-tabs > li > a .icon, .step .nav-tabs > li uib-tab-heading .icon {
      margin-right: 0; }
    .step .nav-tabs > li > a .heading, .step .nav-tabs > li uib-tab-heading .heading {
      display: none; } }

.pricing-table {
  border-left: 1px solid #e7edee; }
  .pricing-table > .pricing-heading {
    border-bottom: 2px solid #29c75f;
    padding: 20px;
    text-align: center; }
    .pricing-table > .pricing-heading > .title {
      background-color: rgba(41, 199, 95, 0.9);
      color: #FFF;
      width: auto;
      margin: 0 auto;
      border-radius: 3px;
      padding: 2px 5px;
      display: inline-block;
      font-size: 0.9em;
      font-weight: 400; }
    .pricing-table > .pricing-heading > .price > .title {
      font-size: 4em;
      text-transform: uppercase;
      font-weight: 200; }
      .pricing-table > .pricing-heading > .price > .title .sign {
        font-size: 0.3em;
        color: #8d9293; }
    .pricing-table > .pricing-heading > .price > .subtitle {
      margin-top: -10px;
      font-size: 0.8em;
      font-weight: 400; }
  .pricing-table > .pricing-body {
    padding: 20px;
    color: #666; }
    .pricing-table > .pricing-body ul.description {
      list-style: none;
      padding: 0;
      margin: 0; }
      .pricing-table > .pricing-body ul.description > li {
        padding: 15px 0px;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #e7edee; }
        .pricing-table > .pricing-body ul.description > li .icon {
          float: left;
          min-width: 25px;
          text-align: center;
          margin-right: 8px; }
      .pricing-table > .pricing-body ul.description > li:last-child {
        border-bottom: 0; }
  .pricing-table > .pricing-footer {
    border-top: 2px solid #e7edee;
    text-align: center;
    padding: 15px; }
    .pricing-table > .pricing-footer .btn-default {
      text-transform: uppercase;
      font-weight: 400;
      color: #666; }
    .pricing-table > .pricing-footer .btn-success {
      color: #FFF; }
  .pricing-table.highlight {
    background-color: #29c75f; }
    .pricing-table.highlight > .pricing-heading {
      border-bottom: 2px solid #18aa4a;
      color: #FFF; }
      .pricing-table.highlight > .pricing-heading > .title {
        background-color: rgba(255, 255, 255, 0.9);
        color: #18aa4a; }
      .pricing-table.highlight > .pricing-heading > .price {
        color: #FFF; }
        .pricing-table.highlight > .pricing-heading > .price .sign {
          color: #FFF; }
    .pricing-table.highlight > .pricing-footer {
      border-top: 2px solid #18aa4a; }
      .pricing-table.highlight > .pricing-footer .btn-default {
        border-color: #18aa4a;
        color: #18aa4a; }
    .pricing-table.highlight > .pricing-body {
      color: #FFF; }
      .pricing-table.highlight > .pricing-body ul.description > li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      .pricing-table.highlight > .pricing-body ul.description > li:last-child {
        border-bottom: 0; }

.panel {
  margin-bottom: 30px;
  border-radius: 3px;
  border-bottom-width: 1px;
  background-color: #FFF;
  border-color: #dfe6e8; }
  .panel .panel-heading {
    padding: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #e7edee; }
  .panel .panel-body {
    padding: 20px; }

.panel.panel-primary {
  border-color: #42b4f2;
  background-color: #FFF; }
  .panel.panel-primary .panel-heading {
    background-color: #86cff7;
    border-bottom-color: #42b4f2;
    color: #043D5D; }

.panel.panel-success {
  border-color: #9df0b9;
  background-color: #FFF; }
  .panel.panel-success .panel-heading {
    background-color: #bafed1;
    border-bottom-color: #9df0b9;
    color: #18aa4a; }

.panel.panel-info {
  border-color: #93e5f3;
  background-color: #FFF; }
  .panel.panel-info .panel-heading {
    background-color: #c3f0f7;
    border-bottom-color: #93e5f3;
    color: #20a3b9; }

.panel.panel-warning {
  border-color: #fbd490;
  background-color: #FFF; }
  .panel.panel-warning .panel-heading {
    background-color: #ffe5b6;
    border-bottom-color: #fbd490;
    color: #e9aa3a; }

.panel.panel-danger {
  border-color: #f3b1ab;
  background-color: #FFF; }
  .panel.panel-danger .panel-heading {
    background-color: #fdc3bd;
    border-bottom-color: #f3b1ab;
    color: #d73727; }

.dataTables_wrapper {
  border-radius: 2px;
  overflow: hidden;
  position: static !important; }
  .dataTables_wrapper .top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    padding-right: 45px; }
    .dataTables_wrapper .top .dataTables_filter {
      width: 100%;
      -ms-flex: 1;
          flex: 1;
      margin-right: 10px; }
      .dataTables_wrapper .top .dataTables_filter label {
        width: 100%;
        display: block;
        font-weight: normal;
        margin-bottom: 0; }
      .dataTables_wrapper .top .dataTables_filter .form-control {
        width: 100%;
        min-width: 200px;
        margin-left: 0;
        color: #8d9293;
        margin-bottom: 0;
        padding: 10px 15px;
        height: 40px;
        border-radius: 20px;
        background-color: transparent; }
    .dataTables_wrapper .top .dataTables_length label {
      width: 100px;
      display: block;
      font-weight: normal;
      margin-bottom: 0; }
    .dataTables_wrapper .top .dataTables_length select {
      width: 100%;
      height: 40px; }
    .dataTables_wrapper .top .dataTables_length .select2 {
      width: 100px !important; }
      .dataTables_wrapper .top .dataTables_length .select2 span.select2-selection {
        border: 1px solid #c8d1d3;
        border-radius: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        height: 43px;
        background-color: transparent; }
      .dataTables_wrapper .top .dataTables_length .select2 .select2-selection__rendered {
        color: #888;
        line-height: 43px;
        padding-left: 15px; }
      .dataTables_wrapper .top .dataTables_length .select2 .select2-selection__arrow {
        height: 46px;
        right: 5px; }
      .dataTables_wrapper .top .dataTables_length .select2 .select2-selection__single .select2-selection__arrow b {
        margin-top: 0;
        transform: translate(0, -50%); }
  .dataTables_wrapper .top::after, .dataTables_wrapper .bottom::after {
    content: '';
    position: relative;
    clear: both;
    display: block; }
  .dataTables_wrapper .bottom {
    border-top: 2px solid #dfe6e8;
    padding: 20px 30px;
    font-size: 14px; }
    .dataTables_wrapper .bottom .dataTables_info {
      float: left;
      color: #8d9293; }
    .dataTables_wrapper .bottom .dataTables_paginate {
      float: right; }
      .dataTables_wrapper .bottom .dataTables_paginate .pagination {
        display: block; }
  .dataTables_wrapper table.dataTable {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .dataTables_wrapper table.dataTable > thead > tr > th {
      background-color: #FFF; }

.loader-container {
  display: none; }

.__loading {
  position: relative;
  overflow: hidden; }
  .__loading .loader-container {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1003;
    color: #8d9293; }
    .__loading .loader-container .icon {
      margin-bottom: 4px; }
    .__loading .loader-container .sk-wave {
      margin-top: 0;
      margin-bottom: 1rem; }
      .__loading .loader-container .sk-wave .sk-rect {
        background-color: #8d9293; }
  .__loading:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    border-radius: 1px; }

div.section {
  margin-bottom: 30px; }
  div.section .section-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: start;
        justify-content: flex-start;
    font-size: 1.2em;
    font-weight: 200;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    color: #8d9293;
    border-bottom: 1px solid #dfe6e8; }
    div.section .section-title .icon {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      background-color: rgba(141, 146, 147, 0.1);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: rgba(141, 146, 147, 0.75);
      margin-right: 10px; }
  div.section .section-body {
    color: #666; }
    div.section .section-body.__indent {
      padding-left: 40px; }

.page-heading {
  padding: 15px;
  padding-left: 26px;
  margin-bottom: 26px; }
  .page-heading .title {
    font-size: 1.3em; }

@media (max-width: 767px) {
  div.section {
    margin-bottom: 15px; }
    div.section .section-title {
      margin-bottom: 15px; }
    div.section .section-body.__indent {
      padding-left: 0px; } }

.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point {
  stroke: #29c75f; }

.ct-series-a .ct-area {
  fill: #29c75f; }

.ct-series-a .ct-slice-pie {
  fill: #29c75f;
  stroke: #FFF;
  stroke-width: 3; }

.ct-series-a .ct-slice-donut {
  stroke: #29c75f; }

.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point {
  stroke: #39c3da; }

.ct-series-b .ct-area {
  fill: #39c3da; }

.ct-series-b .ct-slice-pie {
  fill: #39c3da;
  stroke: #FFF;
  stroke-width: 3; }

.ct-series-b .ct-slice-donut {
  stroke: #39c3da; }

.ct-series-c .ct-bar, .ct-series-c .ct-line, .ct-series-c .ct-point {
  stroke: #666; }

.ct-series-c .ct-area {
  fill: #666; }

.ct-series-c .ct-slice-pie {
  fill: #666666;
  stroke: #FFF;
  stroke-width: 3; }

.ct-series-c .ct-slice-donut {
  stroke: #666; }

.ct-series-d .ct-bar, .ct-series-d .ct-line, .ct-series-d .ct-point {
  stroke: #8d9293; }

.ct-series-d .ct-area {
  fill: #8d9293; }

.ct-series-d .ct-slice-pie {
  fill: #8d9293;
  stroke: #FFF;
  stroke-width: 3; }

.ct-series-d .ct-slice-donut {
  stroke: #8d9293; }

.ct-series-e .ct-bar, .ct-series-e .ct-line, .ct-series-e .ct-point {
  stroke: #c8d1d3; }

.ct-series-e .ct-area {
  fill: #c8d1d3; }

.ct-series-e .ct-slice-pie {
  fill: #c8d1d3;
  stroke: #FFF;
  stroke-width: 3; }

.ct-series-e .ct-slice-donut {
  stroke: #c8d1d3; }

.ct-label {
  font-size: 0.85em; }

.ct-chart-pie .ct-label {
  fill: #FFF;
  font-weight: 200;
  font-size: 1em; }

.ct-chart-donut .ct-label {
  fill: #8d9293;
  font-weight: 200;
  font-size: 1em; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 0.5px;
  stroke-dasharray: 0; }

.card-banner .ct-series-a .ct-bar, .card-banner .ct-series-a .ct-line, .card-banner .ct-series-a .ct-point {
  stroke: #FFF; }

.card-banner .ct-series-a .ct-area {
  fill: #FFF; }

.card-banner .ct-series-a .ct-slice-pie {
  fill: white;
  stroke: #FFF;
  stroke-width: 3; }

.card-banner .ct-series-a .ct-slice-donut {
  stroke: #FFF; }

.card-banner .ct-series-b .ct-bar, .card-banner .ct-series-b .ct-line, .card-banner .ct-series-b .ct-point {
  stroke: #FFF; }

.card-banner .ct-series-b .ct-area {
  fill: #FFF; }

.card-banner .ct-series-b .ct-slice-pie {
  fill: white;
  stroke: #FFF;
  stroke-width: 3; }

.card-banner .ct-series-b .ct-slice-donut {
  stroke: #FFF; }

.card-banner .ct-series-c .ct-bar, .card-banner .ct-series-c .ct-line, .card-banner .ct-series-c .ct-point {
  stroke: #FFF; }

.card-banner .ct-series-c .ct-area {
  fill: #FFF; }

.card-banner .ct-series-c .ct-slice-pie {
  fill: white;
  stroke: #FFF;
  stroke-width: 3; }

.card-banner .ct-series-c .ct-slice-donut {
  stroke: #FFF; }

.card-banner .ct-grid {
  stroke: rgba(255, 255, 255, 0.2); }

.card-banner .ct-label {
  fill: #FFF !important;
  color: #FFF !important; }

.chart-label {
  list-style: none;
  padding: 0;
  margin: 0; }
  .chart-label > li {
    margin-bottom: 5px;
    padding-left: 30px;
    height: 20px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .chart-label > li:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%); }
  .chart-label .ct-series-a:after {
    background-color: #29c75f; }
  .chart-label .ct-series-b:after {
    background-color: #39c3da; }
  .chart-label .ct-series-c:after {
    background-color: #666; }
  .chart-label .ct-series-d:after {
    background-color: #8d9293; }
  .chart-label .ct-series-e:after {
    background-color: #c8d1d3; }

.ct-chart-sale .ct-label {
  -ms-flex-pack: center;
      justify-content: center;
  transform: translate(-50%, 0);
  margin-top: 5px;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.6);
  fill: rgba(255, 255, 255, 0.6); }

.ct-chart-sale .ct-series > text {
  color: rgba(255, 255, 255, 0.6); }

.ct-chart-sale .ct-series > text:first-of-type {
  display: none; }

.timeline {
  position: relative; }
  .timeline dl > dt.item {
    display: block;
    position: relative;
    width: 100%; }
    .timeline dl > dt.item .marker {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 50%;
      margin-left: -15px;
      background-color: #29c75f;
      border-radius: 15px;
      margin-top: 15px;
      z-index: 1007;
      border: 4px solid #FFF;
      box-shadow: 0 1px 1px rgba(68, 68, 68, 0.05); }
    .timeline dl > dt.item .event {
      width: 50%;
      padding-left: 0px;
      padding-right: 40px;
      margin-bottom: 30px; }
      .timeline dl > dt.item .event .event-body {
        background-color: #FFF;
        border-radius: 2px;
        padding: 30px;
        font-size: 1em;
        font-weight: 200;
        box-shadow: 0 1px 2px rgba(68, 68, 68, 0.1); }
      .timeline dl > dt.item .event::before {
        content: '';
        display: block;
        position: absolute;
        margin-top: 16px;
        margin-left: -40px;
        left: 50%;
        top: 0;
        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 14px solid #F5F5F5; }
      .timeline dl > dt.item .event::after {
        content: '';
        display: block;
        position: absolute;
        margin-top: 18px;
        margin-left: -40px;
        left: 50%;
        top: 0;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 12px solid #FFF; }
  .timeline dl > dt.item.pos-right .event {
    float: right;
    width: 50%;
    padding-left: 40px;
    padding-right: 0px;
    margin-bottom: 30px; }
    .timeline dl > dt.item.pos-right .event .event-body {
      background-color: #FFF;
      border-radius: 2px;
      padding: 30px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
  .timeline dl > dt.item.pos-right .event::before {
    content: '';
    display: block;
    position: absolute;
    margin-top: 18px;
    margin-left: auto;
    margin-right: -40px;
    left: auto;
    right: 50%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 0px;
    border-right: 14px solid #F5F5F5; }
  .timeline dl > dt.item.pos-right .event::after {
    content: '';
    display: block;
    position: absolute;
    margin-top: 18px;
    margin-left: auto;
    margin-right: -40px;
    left: auto;
    right: 50%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 0px;
    border-right: 12px solid #FFF; }
  .timeline dl > dt.item::after {
    content: '';
    position: relative;
    display: block;
    clear: both; }
  .timeline dl > dt.heading > .title {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 1009;
    width: 180px;
    background-color: #DDD;
    color: #555;
    padding: 4px 12px;
    text-align: center;
    margin-bottom: 30px; }
  .timeline dl::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    left: 50%;
    margin-left: -2px;
    background-color: #DDD;
    z-index: 1006;
    border-radius: 2px; }

.timeline.pos-right dl > dt.item .marker {
  left: 0;
  margin-left: 0; }

.timeline.pos-right dl > dt.item .event {
  float: right;
  width: 100%;
  padding-left: 50px;
  padding-right: 0px; }

.timeline.pos-right dl > dt.item .event::before {
  content: '';
  display: block;
  position: absolute;
  margin-top: 18px;
  margin-left: auto;
  margin-right: -50px;
  left: auto;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 0px;
  border-right: 14px solid #F5F5F5; }

.timeline.pos-right dl > dt.item .event::after {
  content: '';
  display: block;
  position: absolute;
  margin-top: 18px;
  margin-left: auto;
  margin-right: -50px;
  left: auto;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 0px;
  border-right: 12px solid #FFF; }

.timeline.pos-right dl > dt.heading > .title {
  margin-left: 0px; }

.timeline.pos-right dl::after {
  left: 15px; }

.modal {
  z-index: 200000 !important; }
  .modal .modal-dialog {
    border-radius: 3px; }
    .modal .modal-dialog .modal-content {
      background-color: #FFF;
      border: 3px;
      font-size: 16px;
      box-shadow: 0 1px -1px #666; }
      .modal .modal-dialog .modal-content .modal-header {
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        background-color: #FFF;
        padding: 30px; }
      .modal .modal-dialog .modal-content .modal-body {
        padding: 30px; }
      .modal .modal-dialog .modal-content .modal-footer {
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        background-color: #e7edee; }
        .modal .modal-dialog .modal-content .modal-footer .btn {
          margin-bottom: 0; }

.modal-backdrop {
  z-index: 199999 !important; }

.simplio-example.__modal {
  display: block; }
  .simplio-example.__modal .modal {
    position: relative;
    display: block;
    left: auto;
    right: auto;
    top: auto;
    z-index: 1 !important; }

.label {
  border-radius: 2px;
  font-weight: 400;
  font-size: 0.9em; }
  .label.label {
    background-color: #8d9293; }
    .label.label-default {
      background-color: #8d9293; }
    .label.label-primary {
      background-color: #095077; }
    .label.label-success {
      background-color: #29c75f; }
    .label.label-info {
      background-color: #39c3da; }
    .label.label-warning {
      background-color: #FFBC11; }
    .label.label-danger {
      background-color: #E74C3C; }

pre {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  border: 0; }
  pre code.hljs {
    padding: 30px; }

.list-group {
  position: relative; }
  .list-group > .list-group-item {
    padding: 12px 16px;
    margin-bottom: 2px;
    border-radius: 1px;
    position: relative; }
    .list-group > .list-group-item.list-group-item-primary {
      border: 1px solid #42b4f2;
      background-color: rgba(134, 207, 247, 0.5);
      color: #043D5D; }
    .list-group > .list-group-item.list-group-item-success {
      border: 1px solid #9df0b9;
      background-color: rgba(186, 254, 209, 0.5);
      color: #18aa4a; }
    .list-group > .list-group-item.list-group-item-info {
      border: 1px solid #93e5f3;
      background-color: rgba(195, 240, 247, 0.5);
      color: #20a3b9; }
    .list-group > .list-group-item.list-group-item-warning {
      border: 1px solid #fbd490;
      background-color: rgba(255, 229, 182, 0.5);
      color: #e9aa3a; }
    .list-group > .list-group-item.list-group-item-danger {
      border: 1px solid #f3b1ab;
      background-color: rgba(253, 195, 189, 0.5);
      color: #d73727; }
  .list-group.__timeline > .list-group-item {
    border-radius: 0;
    border: 0;
    padding-left: 40px;
    color: #18aa4a; }
    .list-group.__timeline > .list-group-item:after {
      content: '';
      z-index: 1;
      position: absolute;
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: 8px;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #29c75f; }
  .list-group.__timeline:after {
    content: '';
    z-index: 1;
    position: absolute;
    display: block;
    width: 4px;
    left: 16px;
    top: 0;
    height: 100%;
    border-radius: 2px;
    transform: translate(-50%, 0);
    background-color: rgba(41, 199, 95, 0.2); }

ul.social {
  padding: 0;
  list-style: none;
  margin: 0; }
  ul.social > li {
    padding: 0; }
    ul.social > li > a {
      display: block;
      color: #DDD;
      line-height: 30px;
      vertical-align: middle; }
      ul.social > li > a .icon {
        width: 30px;
        height: 30px;
        line-height: 34px;
        margin-right: 6px;
        font-size: 22px;
        text-align: center;
        display: inline-block;
        vertical-align: middle; }
      ul.social > li > a .title {
        font-size: 14px;
        vertical-align: middle; }

ul.tags {
  list-style: none;
  overflow: auto;
  width: 100%;
  margin: 0;
  padding: 0; }
  ul.tags > li {
    float: left; }
    ul.tags > li > a {
      color: #666;
      display: block;
      border-radius: 2px;
      border: 1px solid #dfe6e8;
      padding: 4px 8px;
      margin: 5px;
      font-size: 12px; }
      ul.tags > li > a:hover {
        color: #FFF;
        border: 1px solid #29c75f;
        background-color: #29c75f;
        cursor: pointer; }
  ul.tags::after {
    position: relative;
    clear: both;
    display: block;
    content: ''; }

footer.app-footer {
  padding-left: 30px;
  padding-right: 30px; }
  footer.app-footer .footer-copyright {
    margin-bottom: 30px;
    color: #8d9293;
    font-size: 0.9em; }

@media (max-width: 767px) {
  footer.app-footer {
    padding-left: 15px;
    padding-right: 15px; }
    footer.app-footer .footer-copyright {
      margin-bottom: 15px; } }

.autocomplete-suggestions {
  box-shadow: 0 1px 1px #dfe6e8;
  background-color: #FFF;
  padding: 0;
  z-index: 100010 !important;
  transform: translate(20px, 0); }
  .autocomplete-suggestions .autocomplete-suggestion {
    padding: 15px 30px;
    border-bottom: 1px solid #e7edee; }
    .autocomplete-suggestions .autocomplete-suggestion strong {
      color: #29c75f; }
    .autocomplete-suggestions .autocomplete-suggestion:hover {
      background-color: #29c75f;
      color: #FFF;
      cursor: pointer; }
      .autocomplete-suggestions .autocomplete-suggestion:hover strong {
        color: #FFF; }

.btn-floating {
  position: fixed;
  top: auto;
  left: auto;
  bottom: 30px;
  right: 30px;
  width: auto;
  height: auto;
  transform: translateZ(0);
  z-index: 101108; }
  .btn-floating .btn-bg {
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    right: auto;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #29c75f;
    box-shadow: 0 1px 3px #29c75f;
    z-index: 101108;
    transition: all 0.3s ease; }
  .btn-floating .btn-toggle {
    position: relative;
    width: 60px;
    height: 60px;
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: 101110; }
    .btn-floating .btn-toggle i {
      font-size: 2em;
      color: #FFF; }
    .btn-floating .btn-toggle .help-text {
      display: none; }
  .btn-floating .toggle-content {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    z-index: 101110;
    display: none; }
    .btn-floating .toggle-content ul.actions {
      list-style: none;
      padding: 0;
      margin: 0; }
      .btn-floating .toggle-content ul.actions > li {
        padding: 0; }
        .btn-floating .toggle-content ul.actions > li a {
          display: block;
          color: #FFF;
          text-decoration: none;
          padding: 10px 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          text-transform: uppercase;
          font-size: 0.9em; }
          .btn-floating .toggle-content ul.actions > li a:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1); }
  .btn-floating.active .btn-bg {
    border-radius: 3px; }
  .btn-floating.active .btn-toggle {
    display: none; }
  .btn-floating.active .toggle-content {
    position: relative;
    display: block; }

.thumbnail {
  padding: 4px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08); }
  .thumbnail img {
    border-radius: 2px; }
  .thumbnail .caption {
    text-align: center; }
    .thumbnail .caption .title {
      margin-top: 10px;
      margin-bottom: 5px; }
    .thumbnail .caption .description {
      font-size: 14px; }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .media .media-left {
    margin-right: 7.5px; }
    .media .media-left img {
      border-radius: 3px;
      max-width: 60px;
      height: auto; }

.media.social-post {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .media.social-post .media-left {
    margin-right: 7.5px; }
    .media.social-post .media-left img {
      border-radius: 50%; }
  .media.social-post .media-body .media-heading {
    margin-bottom: 10px; }
    .media.social-post .media-body .media-heading .title {
      margin-bottom: 3px;
      margin-top: 0; }
    .media.social-post .media-body .media-heading .timeing {
      opacity: 0.4;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 0.9em; }
  .media.social-post .media-body .media-content {
    margin-bottom: 10px; }
    .media.social-post .media-body .media-content .attach {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      -ms-flex-align: start;
          align-items: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
      .media.social-post .media-body .media-content .attach .thumbnail {
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 1px; }
  .media.social-post .media-body .media-action .btn {
    padding: 4px 0;
    margin-right: 10px;
    color: #8d9293;
    font-size: 0.9em; }
  .media.social-post .media-body .media-comment input {
    padding: 7.5px 15px; }
