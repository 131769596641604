.st0{fill:#FFFFFF;}

.swal2-popup {
    font-size: 1.6rem !important;
  }

._loading_overlay_overlay {
    z-index: 999999 !important;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
body.error404 {
  background: #5A657A;
  color: white;
  font-family: "Roboto", sans-serif;
}
body.error404 h1 {
  margin-bottom: 20px;
}
body.error404 p {
  font-size: 18px;
  line-height: 30px;
}
body.error404 a {
  color: #FBD160;
}

.error404 svg {
  display: block;
  margin: 0 auto;
  overflow: visible !important;
}
.error404 svg #robot_1_ {
  animation-name: verticalAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
.error404 svg #light_1_ {
  animation-name: blinkLight;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
}
.error404 svg #leftEye_1_, svg #rightEye_1_ {
  animation-name: blinkEye;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
}
.error404 svg #leftArm_1_ {
  animation-name: moveLeftArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
  transition: all;
}
.error404 svg #rightArm_1_ {
  animation-name: moveRightArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
}
.error404 svg #shadow_1_ {
  animation-name: shadowAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50%;
}

@keyframes wave {
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(170deg);
  }
}
@keyframes moveLeftArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(4deg);
  }
}
@keyframes moveRightArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes shadowAnimation {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.15);
    opacity: 0.6;
  }
}
@keyframes verticalAnimation {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
@keyframes blinkLight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinkEye {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
}

.msts {
	user-select: none;
	cursor: default;
}

.msts_disabled {
}

.msts__heading {
	display: flex;
}

.msts__subheading {
	display: flex;
}

.msts__body {
	display: flex;
}

.msts__footer {
	display: flex;
}

.msts__side {
	width: 50%;
}

.msts__side_available {
}

.msts__side_selected {
}

.msts__side_controls {
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.msts__list {
	padding: 0;
	margin: 0;
}

.msts__list-item {
	list-style-type: none;
	cursor: pointer;
}

.msts__list-item_disabled {
	cursor: default;
}

.msts__list-item_highlighted {
}

.msts__control {
}

.msts__control_select-all {
}

.msts__control_deselect-all {
}

.msts__filter-input {
}

/* Example theme */
.msts_theme_example {
  border: 1px solid silver;
}
.msts_theme_example .msts__heading .msts__side {
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.msts_theme_example .msts__heading .msts__side_available {
  background-color: #295077;
}
.msts_theme_example .msts__heading .msts__side_selected {
  background-color: #20b05c;
}
.msts_theme_example .msts__subheading .msts__side_filter {
  padding: 5px;
}
.msts_theme_example .msts__footer .msts__side {
  padding: 5px 15px;
  background-color: #ecf0f1;
  font-size: 0.75em;
  color: #7f8c8d;
  text-align: right;
}
.msts_theme_example .msts__list {
  height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}
.msts_theme_example .msts__list-item {
  padding: 5px 10px;
  transition: background-color ease-in 0.1s, color ease-in 0.1s;
}
.msts_theme_example .msts__list-item:hover {
  background-color: #2980b9;
  color: #fff;
}
.msts_theme_example .msts__list-item_disabled {
  background-color: #ecf0f1;
  color: #7f8c8d;
}
.msts_theme_example .msts__list-item_disabled:hover {
  background-color: #ecf0f1;
  color: #7f8c8d;
}
.msts_theme_example .msts__list-item_highlighted {
  background-color: rgba(41, 128, 185, 0.25);
}
.msts_theme_example .msts__control {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 3px;
  color: #bdc3c7;
  transition: color ease 0.15s;
}
.msts_theme_example .msts__control:hover {
  color: #95a5a6;
}
.msts_theme_example .msts__control[disabled] {
  color: #ecf0f1;
}
.msts_theme_example .msts__control_select-all:after {
  content: '❯';
}
.msts_theme_example .msts__control_deselect-all:after {
  content: '❮';
}
.msts_theme_example .msts__filter {
  position: relative;
}
.msts_theme_example .msts__filter-input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid silver;
}
.msts_theme_example .msts__filter-clear {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 1.25em;
  color: silver;
  transition: color ease 0.15s;
}
.msts_theme_example .msts__filter-clear:after {
  content: '×';
  vertical-align: middle;
}
.msts_theme_example .msts__filter-clear:hover {
  color: #c0392b;
}
.msts_theme_example.msts_disabled {
  background-color: #ecf0f1;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.remove_margin_bottom {
  margin-bottom: 0 !important;
}